import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import OperationsSelector from "components/OperationsSelector";
//import { Select } from "@mantine/core";
import { Stack, Grid, FormControl } from "@mui/material";
//import { Tooltip, Button } from "@mantine/core";
import GetEntriesTable from "./ConsequenceEntry";
import { LOAD_OPERATIONS } from "GraphQL/GetAllOperation";
import AddEntry from "./AddEntry";
//import AddEntry from "components/consequences/AddEntry";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "pictures/bundlogo.svg";
import { useNavigate } from "react-router-dom";

window.journalid = "";
window.$name = "";

//**************************** DECLARE CONST *****************************************/
const Getconsequences = (props) => {
  let filterState = props.filterState || ["CLOSED"];
  const { loading, error, data } = useQuery(LOAD_OPERATIONS);
  const [selectedOp, setSelectedOp] = useState("");
  const { t } = useTranslation("consequence");
  const navigate = useNavigate();


  useEffect(() => {
    if (selectedOp.id !== undefined) {
      window.journalid = selectedOp.id;
      window.$name = selectedOp.name;
      document.title = t("getallconsequences.registertitle") + selectedOp.name;
      navigate("/consequences/" + selectedOp.id);
    }
  }, [selectedOp, navigate]);

  //**************************** DECLARE CONST *****************************************/

  //******************** HANDLECHANGE COMBOBOX *****************************************/
  //******************** HANDLECHANGE COMBOBOX *****************************************/

  if (loading) return <div>Loading...</div>;

  if (error) return `Error! ${error.message}`;
  //console.log("Result query",data.allOperation)
  if (data.allOperation.lenght === "0") {
    //  console.log("operation empty")
  }
  let operations = data.allOperation.filter(
    (j) => !filterState.includes(j.state)
  );

  //Set selected from url if not already set
  if (props.id !== undefined) {
    let urlParam = operations.filter((j) => j.id === props.id);
    if (selectedOp === "" && urlParam.length === 1) setSelectedOp(urlParam[0]);
  }

  if (data) {
    window.journalid = selectedOp.id;
    window.$name = selectedOp.name;
    return (
      <div style={{ fontFamily: "Arial" }}>
        <Stack>
          <div>
            <Logo
              className="header"
              alt="Bund Logo"
              style={{
                width: 350,
                height: 80,
                padding: 5,
                display: "inline",
                float: "left",
              }}
            />
            <div style={{ float: "right", padding: 5, margin: 10 }}>
              {t("getallconsequences.headerdepartemenrt1")}
              <br></br>
              {t("getallconsequences.headerdepartemenrt2")}
              <br></br> <strong>{t("getallconsequences.headerarmee")}</strong>{" "}
            </div>
          </div>
          <div></div>
        </Stack>
        <Stack
          style={{ padding: 7 }}
          className="content"
          spacing={2}
          sx={{ maxWidth: "50ch" }}
        >
          <Grid container>
            <FormControl fullWidth>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={11}>
                  <OperationsSelector
                    filterState={[]}
                    onSetOperation={(op) => setSelectedOp(op)}
                    value={!(selectedOp === null) ? selectedOp.id : ""}
                  />
                </Grid>

                <AddEntry journalid={selectedOp.id} />
              </div>
            </FormControl>
          </Grid>
        </Stack>
        <GetEntriesTable
          operation={selectedOp.id}
          oprationame={selectedOp.name}
          refresh={60}
        />
      </div>
    );
  }
};
//<EditJournal />
//onClick={() => setConfigOpen(!configOpen)}
//  <AddEntry journalid={selected.value}/>
export default Getconsequences;

import { LOAD_TASKLISTS } from "GraphQL/Queries_Load_AllTasklist";
import { useQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import Selector from "components/ListSelector";
import FormControl from "@mui/material/FormControl";
import React from "react";
import GetEntriesTable from "./MaterialReactTable";
import AddEntry from "./AddEntry";
import { Stack, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "pictures/bundlogo.svg";
import { useNavigate } from "react-router-dom";

window.journalid = "";
window.$name = "";

//**************************** DECLARE CONST *****************************************/
const GetTaskLists = (props) => {
  let filterState = props.filterState || ["CLOSED"];
  const { loading, error, data } = useQuery(LOAD_TASKLISTS);
  const [selectedT, setSelectedT] = useState("");
  const { t } = useTranslation("tasklist");
  const navigate = useNavigate();


  useEffect(() => {
    if (selectedT.id !== undefined) {
      window.journalid = selectedT.id;
      window.$name = selectedT.name;
      document.title = t("getalltasklists.registertitle") + selectedT.name;
      navigate("/tasklist/" + selectedT.id);
    }
  }, [selectedT, navigate]);

  //**************************** DECLARE CONST *****************************************/
  //******************** HANDLECHANGE COMBOBOX *****************************************/

  //******************** HANDLECHANGE COMBOBOX *****************************************/

  if (loading) return <div>Loading...</div>;

  if (error) return `Error! ${error.message}`;

  let tasks = data.allTasklist.filter((j) => !filterState.includes(j.state));

  //Set selectedT from url if not already set
  if (props.id !== undefined) {
    let urlParam = tasks.filter((j) => j.id === props.id);
    if (selectedT === "" && urlParam.length === 1) {
      setSelectedT(urlParam[0]);
    }
  }

  window.journalid = selectedT.id;
  window.$name = selectedT.name;
  if (data) {
    return (
      <div style={{ fontFamily: "Arial" }}>
        <Stack>
          <div>
            <Logo
              className="header"
              alt="Bund Logo"
              style={{
                width: 350,
                height: 80,
                padding: 5,
                display: "inline",
                float: "left",
              }}
            />
            <div style={{ float: "right", padding: 5, margin: 10 }}>
              {t("getalltasklists.headerdepartemenrt1")}
              <br></br>
              {t("getalltasklists.headerdepartemenrt2")}
              <br></br> <strong>{t("getalltasklists.headerarmee")}</strong>{" "}
            </div>
          </div>
          <div></div>
        </Stack>
        <Stack
          style={{ padding: 7 }}
          className="content"
          spacing={2}
          sx={{ maxWidth: "50ch" }}
        >
          <Grid container>
            <FormControl fullWidth>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Selector
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                    control: (provided) => ({ ...provided, minWidth: "150px" }),
                  }}
                  placeholder={t("getalltasklists.titletasklist")}
                  filterState={[]}
                  onSetList={(op) => setSelectedT(op)}
                  value={!(selectedT === null) ? selectedT.id : ""}
                  type="t"
                />
                <AddEntry tasklist={selectedT} />
              </div>
            </FormControl>
          </Grid>
        </Stack>
        <GetEntriesTable
          tasklist={selectedT.id}
          tasklistname={selectedT.name}
          refresh={60}
        />
      </div>
    );
  }
};

export default GetTaskLists;

import { gql } from "@apollo/client";



export const CREATE_ENTRY = gql`
  mutation addjournalentry(
    $journalid: ID!
    $content: String!
    $name: String!
  ) {
    addJournalEntry(
      entry: {
        journal: $journalid
        createdBy: { name: $name }
        content: $content
      }
    ) {
      id
      journal {
        id
      }
      createdAt
      content
      createdBy {
        name
      }
      followup
      followupBy {
        name
      }
      tags
    }
  }
`; 
  export default CREATE_ENTRY
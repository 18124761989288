import { gql } from "@apollo/client";



export const MODIFY_ENTRY = gql`
mutation modifyConsequenceEntry(
  $operation : ID!
  $id: ID!
  $content: String
  $createdBy : String!
  $sourcedata : String
  $hrl: Boolean
  $er: Boolean
  $kr: String
  $ek: Boolean
  $ef: Boolean
){
  modifyConsequenceEntry(entry:{
        operation: $operation
        id: $id
        content: $content
        source: $sourcedata
        createdBy:{name: $createdBy}
        ek: $ek
        ef: $ef
        hrl:$hrl
        kr:$kr
        er:$er
      })
      {
      operation{
        id
      }
      id
      createdAt
      content
      createdBy{name}
      hrl
      er
      kr
      ek
      ef
      source
      lastUpdate
  }
    }
      `;
export default MODIFY_ENTRY;


/* hrl: $hrl
er: $er
ek: $ek
ef: $ef */
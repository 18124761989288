import { ButtonGroup, Button, Stack, Typography, TextField, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState, Fragment } from "react";
import { useMutation } from "@apollo/client";


import OperationsSelector from "components/OperationsSelector"
import AddOperation from "./AddOperation";
import { MOD_OPERATION, GET_ALLOPERATION } from "GraphQL/Operations";


export default function OperationsTab() {
    const { t } = useTranslation("admin");
    const [selectedOp, setSelectedOp] = useState(null);
    const [ModOp, { loading, error }] = useMutation(MOD_OPERATION);

    function changeHandler(event) {
        const { name, value } = event.target;
        setSelectedOp(selectedOp => ({
            ...selectedOp, [name]: value
        }))
    }

    function isValidOp() {
        if (selectedOp.name.length < 3) return false;
        return true;
    }

    function save(){
        if(isValidOp){
            ModOp({
                variables: {
                    op: (({ __typename, ...o }) => o)(selectedOp)
                },
                refetchQueries: [{ query: GET_ALLOPERATION }]
            })
        }
    }

    return (
        <Stack className="content" spacing={2} sx={{ maxWidth: "50ch" }}>
            <Typography variant="h2">{t("operationsTabTitle")}</Typography>
            <p>{t("selectOperation")}</p>
            <Grid container>
                <Grid item xs={11}><OperationsSelector filterState={[]} onSetOperation={(op) => setSelectedOp(op)}  value={!(selectedOp === null) ? selectedOp.id : ""}/></Grid>
                <Grid item xs={1}><AddOperation onSetOperation={(op) => setSelectedOp(op)} sx={{ justify: "end" }} /></Grid>
            </Grid>
            {(selectedOp !== null) ?
                <Fragment>
                    <Typography variant="h5">{selectedOp.name}</Typography>
                    <TextField disabled label={t("lblid")} value={selectedOp.id} />
                    <TextField label={t("lblname")} value={selectedOp.name} name="name" onChange={changeHandler} />
                    <ButtonGroup fullWidth name="state" onChange={changeHandler} value>
                        {["PLANNED", "ACTIVE", "CLOSED"].map(state =>
                            <Button
                                variant={(state === selectedOp.state) ? "contained" : "outlined"}
                                onClick={changeHandler}
                                name="state"
                                value={state}
                                key={state}
                            >{state}
                            </Button>)
                        }
                    </ButtonGroup>
                    <Button disabled={!isValidOp()} variant="outlined" onClick={save}>{t("btnsave")}</Button>
                    {(loading) ? <Typography>Submitting...</Typography> : null}
                    {(error) ? <Typography>Submission error!<br />{error.message}</Typography> : null}
                </Fragment>
                : null}
        </Stack>
    );
}
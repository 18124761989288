import React, { Fragment, useMemo } from 'react';
import { MaterialReactTable } from "material-react-table";
import { useQuery } from "@apollo/client";
import { GET_FOLDER_BY_OP_ID } from 'GraphQL/GetFolderByOperationId';
import { Alert, Stack, Tooltip, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export default function FileBrowser(props) {
  let path = props.path
  let clientPath = props.clientPath
  let operation = props.operation.id
  let name = props.name

  const { loading, error, data } = useQuery(GET_FOLDER_BY_OP_ID, {
    variables: { operation, path },
    pollInterval: 60000,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: 'path',
        header: 'Link',
        Cell: ({ renderedCellValue, row }) => (
          <a href={renderedCellValue.replace(/'/g, "%27")}><OpenInNewIcon  /></a>
        ),
        size: "0px"
      },
      {
        accessorKey: 'name',
        header: 'Name',
      },

      {
        accessorKey: 'lastUpdate',
        header: 'Last Change',
        Cell: ({ renderedCellValue }) => (
          <div style={{textAlign: "right"}}> {new Date(renderedCellValue).toLocaleString(window.navigator.language)}</div>)
      },
    ],
    [],
  );


  if (data) return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems={'center'} >
      <a href={(clientPath+path).replace(/'/g, "%27")}><OpenInNewIcon/></a>
      <Typography variant="h6" >{name}</Typography>
      </Stack>
      <MaterialReactTable
        columns={columns}
        data={data.getFolderByOperationID}
        initialState={{ density: 'compact' }}
        enableRowSelection={false} //enable some features
        enableGlobalFilter={false} //turn off a feature
        enableTableHead={false}
        enableTopToolbar={false}
        enableBottomToolbar={false}
        enablePagination={false}
        muiTableContainerProps={{ sx: { maxHeight: '200px' } }}
      />
    </Stack>
  );

  if (loading) return 'Loading...';
  if (error) return (
    <Tooltip title={error.message}>
      <Alert severity="error">Error loading folder</Alert>
    </Tooltip>
  );



}
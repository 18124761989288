import * as React from 'react';
import GetEntriesTable from "../components/consequences/ConsequenceEntry"

export default function ConsequencesName() {
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            console.log("windows.$name","Select journal");
            <GetEntriesTable operation={window.journalid} operationname={window.$name} />
        </React.Suspense>
    );
}
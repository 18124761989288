
import { gql } from "@apollo/client";


export const MODIFY_TCONTENT = gql`
mutation modMaximalTasklistEntry(
  $itemid: ID!
  $journalid: ID!
  $content: String!
){
      modifyTasklistEntry(entry:{
        id: $itemid
        tasklist: $journalid
         content: $content
      })
      {
        id
        tasklist{
          id
        }
        createdAt
        content
        createdBy{name}
        priority
        status
        due
      }
    }
      `; 
  export default MODIFY_TCONTENT
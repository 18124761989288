import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { MetroSpinner } from "react-spinners-kit";
import { darken } from "@mui/material";
import logo from "pictures/bundlogo.png";

//****************** IMPORT GQL ****************************/
import MODIFY_ENTRY from "GraphQL/ModifyTaskListEntry";
//import RESTORE from "../GraphQL/restore"; Remove restore function
//ADD NEW EDIT CELL
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

import MODIFY_TCONTENT from "GraphQL/ModifyTaskListContent";

//************************ ICON ****************************/
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

//import RestoreIcon from "@mui/icons-material/Restore";
//************************ ICON ****************************/
import {
  Button,
  Textarea,
  Modal,
  useMantineTheme,
  Center,
} from "@mantine/core";
import { debounce } from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { LOAD_ENTRIEST } from "GraphQL/Tasklists";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useDisclosure } from "@mantine/hooks";
import { MenuItem, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Import useTheme hook

const GetaskList = (tasklist) => {
  //**************************** DECLARE CONST *****************************************/
  //const [makeRestore, { errorR, dataR }] = useMutation(RESTORE);
  const [opened, { open, close }] = useDisclosure(false);
  const [idcontent, setidcontent] = useState(null);
  const theme = useMantineTheme();
  const [selectedContent, setSelectedContent] = useState("");
  const [modContent] = useMutation(MODIFY_TCONTENT);
  const [modTask, { dataj }] = useMutation(MODIFY_ENTRY);
  const { t, i18n } = useTranslation("tasklist");
  const ExcelJS = require("exceljs");

  let MRT_language = "";
  let dateResult = "";

  // console.log("Language : ", i18n.language)
  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(LOAD_ENTRIEST, {
    variables: { tasklist: window.journalid },
    pollInterval: tasklist.refresh * 1000,
  });

  //**************************** DECLARE CONST *****************************************/
  if (dataj);
  //console.log(dataj)

  //************************ NEW EXPORT CSV **************************************** */
  const exportExcelFile = (dataforexecel) => {
    //console.log("data for excel",dataforexecel)
    const workbook = new ExcelJS.Workbook();

    var sheet = workbook.addWorksheet(window.$name, {
      pageSetup: {
        horizontalCentered: true,
        paperSize: 9,
        orientation: "landscape",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    sheet.getRow(1).height = 30;
    sheet.pageSetup.printTitlesColumn = "1:1";
    sheet.autoFilter = {
      from: "A1",
      to: "J1",
    };

    //Define Border of Row 1 Header
    sheet.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
      sheet.getCell(cell.address).border = {
        top: { style: "thick", color: { argb: "FFFF0000" } },
        left: { style: "thick", color: { argb: "00000000" } },
        bottom: { style: "thick", color: { argb: "000000" } },
        right: { style: "thick", color: { argb: "FF00FF00" } },
      };
    });
    sheet.headerFooter.oddFooter = "&L Page &P of &N";

    const columnsStyle = { font: { name: "Arial Black", size: 10 } };

    //Define Headers Colums and set Backgroundcolor
    let Columsexcel = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
    ];
    Columsexcel.forEach((cellAddress) => {
      const cell = sheet.getCell(cellAddress);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00000000" },
        bgColor: { argb: "96C8FB" },
      };
    });

    //Define fonc option
    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 10,
      align: Center,
      bold: true,
      font: { color: { argb: "#FFFFFF" } },
      color: { argb: "FFFFFF" },
    };

    sheet.columns = [
      {
        header: "Id",
        key: "id",
        width: 4,
      },
      {
        header: t("materialreacttable.creationdate"),
        key: "creationDate",
        width: 15,
      },
      {
        header: t("materialreacttable.content"),
        key: "content",
        width: 18,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("materialreacttable.creator"),
        key: "createdByname",
        width: 18,
      },
      {
        header: t("materialreacttable.priority"),
        key: "priority",
        width: 10,
      },
      {
        header: t("materialreacttable.duedate"),
        key: "duedate",
        width: 10,
      },
      {
        header: t("materialreacttable.responsible"),
        key: "responsiblename",
        width: 14,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("materialreacttable.recipient"),
        key: "recipientname",
        width: 12,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("materialreacttable.status"),
        key: "status",
        width: 9,
      },
      {
        header: t("materialreacttable.lastupdate"),
        key: "lastUpdateformated",
        width: 15,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
    ];

    // const promise = Promise.all(
    dataforexecel.forEach((element) => {
      sheet.addRow({
        id: element.id,
        content: element.content,
        creationDate: element.creationDate,
        createdByname: element.createdByname,
        priority: element.priority,
        duedate: element.duedate,
        responsiblename: element.responsiblename,
        recipientname: element.recipientname,
        status: element.status,
        lastUpdateformated: element.lastUpdateformated,
      });
    });

    //Generate the Xlsx file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = window.$name + ".xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  //******************************** Export to PDF  *****************************************************************/
  const downloadPdf = (data) => {
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = logo;

    doc.setFontSize(7.5);
    doc.text(
      window.$name,
      doc.internal.pageSize.getWidth() / 2,
      35,
      null,
      null,
      "center"
    );

    doc.addImage(img, "png", 2, 2, 200, 50);

    doc.setFontSize(7.5);
    doc.text(t("getalltasklists.headerdepartemenrt1"), 600, 15);
    doc.text(t("getalltasklists.headerdepartemenrt2"), 600, 27);
    doc.text(t("getalltasklists.headerarmee"), 600, 39);

    const tableConfig = {
      theme: "striped",
      showHead: "everyPage",
      margin: { top: 60, right: 14, bottom: 50, left: 14 },
      cellWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 23 },
        1: { cellWidth: 100 },
        2: { cellWidth: 70 },
        3: { cellWidth: 150 },
        4: { cellWidth: 70 },
        5: { cellWidth: 60 },
        6: { cellWidth: 80 },
        7: { cellWidth: 70 },
        8: { cellWidth: 65 },
      },
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data,
    };

    doc.autoTable(tableConfig);

    var i = "";
    var pageCount = doc.internal.getNumberOfPages();
    for (i = 0; i < pageCount; i++) {
      doc.setPage(i);
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
      doc.text(
        t("materialreacttable.page") + pageCurrent + "/" + pageCount,
        20,
        550
      );
    }

    doc.save(window.$name + ".pdf");
  };
  //** */****************************** Export to PDF  *****************************************************************/

  //************************************ Restore   *****************************************************************/

  /*  const StartRestore = () => {
    makeRestore(dataR, "error", errorR);
  }; */
  //************************************* restore  *****************************************************************/

  //**************************************** HANDLE SAVE ***********************************************************/
  //ADD HANDLESAVECELL
  const handleSaveCell = (cell, values) => {
    const { row, column } = cell;
    const entry = data.getTasklistEntryByTasklistId[row.index];
    const iddata = entry.id;
    //console.log("ID", data.getTasklistEntryByTasklistId[cell.row.index].id, " Value ", values);
    const formatted = new Date().toISOString().replace("Z", "");

    //ADD FOR CELL EDIT

    let contentdata = column.id === "content" ? values : entry.content;
    let createdBynamedata =
      column.id === "createdByname" ? values : entry.createdBy.name;
    let responsibledata =
      column.id === "responsiblename" ? values : entry.responsible?.name || "";
    let recipientdata =
      entry.recipient !== ""
        ? column.id === "recipientname"
          ? values
          : entry.recipient?.name || ""
        : "";
    let prioritydata = column.id === "priority" ? values : entry.priority || "";

    let duedatedata =
      column.id === "due" ? values : entry.duedate ? null : null;

    let statusdata = column.id === "status" ? values : entry.status || "";

    //console.log("itemid",iddata,"journalid", window.journalid,contentdata,createdBynamedata,responsibledata,recipientdata,statusdata,"lastupdate", formatted,"dudedate",duedatedata)
    let vars = {
      itemid: iddata,
      journalid: tasklist.tasklist,
      content: contentdata,
      creator: createdBynamedata,
      responsible: responsibledata,
      recipient: recipientdata,
      priority: prioritydata,
      status: statusdata,
      lastupdate: formatted,
      due: duedatedata,
    };
    if (values.duedate) vars.due = values.duedate;
    modTask({
      variables: vars,
      refetchQueries: [
        { query: LOAD_ENTRIEST, variables: { tasklist: window.journalid } },
      ],
    });
  };

  //**************************************  Date Difference  ********************************************************************/
  //Calculate the Due Date with actual DateTime
  const datedif = (duedate) => {
    if (duedate !== null) {
      var one_day = 1000 * 60 * 60 * 24;
      var present_date = new Date();
      var due_date = new Date(duedate);
      var Result =
        Math.round(due_date.getTime() - present_date.getTime()) / one_day;
      dateResult = Result.toFixed(0);
      // console.log("due date",due_date, "datediff ", dateResult)
    } else {
      dateResult = null;
      duedate = null;
    }
  };
  //************************************  Date Difference ********************************************************************/

  const priorities = ["HIGH", "MEDIUM", "LOW"];

  const statuss = ["OPEN", "FINISHED", "ARCHIVED"];

  const priorityColumn = {
    accessorKey: "priority",
    header: t("materialreacttable.priority"),
    enableClickToCopy: false,
    muiTableBodyCellEditTextFieldProps: {
      select: true,
      children: priorities.map((priority) => (
        <MenuItem key={priority} value={priority}>
          {priority}
        </MenuItem>
      )),
    },
    size: 15,
    filterVariant: "multi-select",
    filterSelectOptions: priorities,
  };

  // ******************************************** Define Colums for Table *************************************************************
  const columns = useMemo(() => [
    {
      accessorKey: "id", //simple recommended way to define a column
      header: "id",
      size: 2,
      enableEditing: false,
      enableColumnActions: false,
      //  muiTableHeadCellProps: { sx: { color: 'green' } }, //custom props
    },
    {
      accessorKey: "createdAt", //simple recommended way to define a column
      header: t("materialreacttable.creationdate"),
      enableEditing: false,
      size: 40,
      accessorFn: (row) => new Date(row.createdAt), //convert to Date for sorting and filtering
      filterFn: "lessThanOrEqualTo",
      sortingFn: "datetime",
      Cell: ({ cell }) => cell.getValue()?.toLocaleString(), //render Date as a string
      Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      enableColumnFilter: false,
    },
    {
      accessorKey: "content", //simple recommended way to define a column
      header: t("materialreacttable.content"),
      enableClickToCopy: false,
      enableEditing: false,
      Cell: ({ cell, row, column }) => (
        <span
          style={{ whiteSpace: "pre-line" }}
          onDoubleClick={() => handleContentClick(row)}
        >
          <div onDoubleClick={() => handleContentClick(row)}>
            {cell.renderValue()}
          </div>
        </span>
      ),
      muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
        variant: "outlined",
        multiline: true,
      }), //Add this line to the column definition
      size: 60,
    },
    {
      accessorKey: "createdByname",
      header: t("materialreacttable.creator"),
      size: 30,
    },
    priorityColumn,
    {
      accessorKey: "due",
      header: t("materialreacttable.duedate"),
      size: 40,
      muiTableBodyCellEditTextFieldProps: {
        type: "datetime-local",
      },
      Cell: ({ cell }) => {
        if (cell.getValue() > 0) {
          datedif(cell.getValue())?.toLocaleString();
          return (
            <Box
              component="span"
              sx={(theme) => ({
                backgroundColor:
                  dateResult < 2
                    ? theme.palette.error.light
                    : dateResult < 14
                    ? "#FFFACD"
                    : theme.palette.success.light,
                borderRadius: "0.25rem",
                color: "#000",
                maxWidth: "18ch",
                p: "0.25rem",
              })}
            >
              {cell.getValue().toLocaleString()}
            </Box>
          );
        } else {
          return null;
        }
      },
      accessorFn: (row) => new Date(row.due), //convert to Date for sorting and filtering
    },
    {
      accessorKey: "responsiblename",
      header: t("materialreacttable.responsible"),
      size: 40,
    },
    {
      accessorKey: "recipientname",
      header: t("materialreacttable.recipient"),
      size: 40,
    },
    {
      accessorKey: "status",
      header: t("materialreacttable.status"),
      enableClickToCopy: false,
      muiTableBodyCellEditTextFieldProps: {
        select: true,
        children: statuss.map((status) => (
          <MenuItem onChange={handleSaveCell} key={status} value={status}>
            {status}
          </MenuItem>
        )),
      },
      size: 15,
      filterVariant: "multi-select",
      filterSelectOptions: statuss,
      Cell: ({ cell }) => {
        const theme = useTheme();
        const backgroundColor =
          cell.getValue() < "FINISH" && cell.getValue() < "ARCHIVED"
            ? theme.palette.error.light
            : cell.getValue() >= "OPEN"
            ? "#FFFACD"
            : theme.palette.success.light;

        return (
          <Box
            sx={{
              backgroundColor,
              borderRadius: "0.25rem",
              color: "#000",
              maxWidth: "55ch",
              p: "0.5rem",
            }}
          >
            {cell.getValue()?.toLocaleString?.("de-DE", {})}
          </Box>
        );
      },
    },
    {
      accessorKey: "lastUpdateformated", //simple recommended way to define a column
      header: t("materialreacttable.lastupdate"),
      accessorFn: (row) => new Date(row.lastUpdate), //convert to Date for sorting and filtering
      sortingFn: "datetime",
      size: 50,
      Cell: ({ cell }) => cell.getValue()?.toLocaleString(), //render Date as a string
      Header: ({ column }) => <em>{column.columnDef.header}</em>, //custom header markup
      enableColumnFilter: false,
    },
  ]);

  //**************************** HANDLE MODAL *****************************************/
  const handleContentClick = (row) => {
    // Set the value of content and itemid
    setSelectedContent(row.original.content);
    setidcontent(row.original.id);
    open();

    // setIsModalOpen(true);
  };

  // Debounce the input handling function
  const debouncedHandleChange = debounce((value) => {
    setSelectedContent(value);
  }, 700); // Adjust the debounce delay as needed

  const handleSaveChanges = () => {
    modContent({
      variables: {
        itemid: idcontent,
        journalid: window.journalid,
        content: selectedContent,
      },
    });
    close();
  };
  //**************************** HANDLE MODAL *****************************************/

  // ******************************************** Define Colums for Table *************************************************************
  function formatDate(string) {
    if (string !== null) {
      var options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return new Date(string).toLocaleDateString([], options);
    } else {
      string = null;
    }
  }

  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={40} color="black" loading={loading} />
      </div>
    );
  }

  if (error) return console.log("error");

  if (data) {
    //console.log("Result Query array", data);
    let queryresult = data.getTasklistEntryByTasklistId.map((element) => {
      let e = structuredClone(element);
      e.createdByname = element.createdBy.name;
      if (
        element.createdAt !== null &&
        element.createdAt !== undefined &&
        element.createdAt !== ""
      ) {
        e.creationDate = formatDate(element.createdAt);
        //e.creationDate =element.createdAt.slice(8, 10) + "." + element.createdAt.slice(5, 7) + "." + element.createdAt.slice(2, 4) + " " + element.createdAt.split("T")[1].slice(0, 5);
      }
      if (
        element.due !== null &&
        element.due !== undefined &&
        element.due !== ""
      ) {
        /*         e.due =
        element.due.slice(0, 10) +
        "    " +
        element.due.split("T")[1].slice(0, 5); */
        // e.duedate = formatDate(element.due)
        e.duedate = element.due;
      } else {
        e.duedate = null;
      }

      if (
        element.lastUpdate !== null &&
        element.lastUpdate !== undefined &&
        element.lastUpdate !== ""
      ) {
        e.lastUpdateformated = formatDate(element.lastUpdate);
        // e.lastUpdate =element.lastUpdate.slice(8, 10) + "." + element.lastUpdate.slice(5, 7) + "." + element.lastUpdate.slice(0, 4) + " " + element.lastUpdate.split("T")[1].slice(0, 5);
      }

      if (
        element.responsible !== undefined &&
        element.responsible !== "" &&
        element.responsible !== null
      ) {
        e.responsiblename = element.responsible.name;
      } else {
        e.responsiblename = "";
      }
      if (
        element.recipient !== undefined &&
        element.recipient !== "" &&
        element.recipient !== null
      ) {
        e.recipientname = element.recipient.name;
      } else {
        e.recipientname = "";
      }
      return e;
    });

    return (
      <>
        <MaterialReactTable
          columns={columns}
          data={queryresult}
          editingMode="cell"
          enableEditing
          //DT201 ADD edit on doubleclick
          // onEditingRowChange={handleSaveRow}
          muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value, row);
              //console.log("EDIT ", cell, " event ",event.target.value )
            },
          })}
          //DT201 ADD edit on doubleclick
          enableColumnResizing
          enableColumnOrdering
          enableClickToCopy
          enableColumnFilterChangeMode
          enableFullScreenToggle={false}
          maxsize="100%"
          options={{}}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.1),
              },
            }),
          }}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
          }}
          renderBottomToolbarCustomActions={() => (
            <Typography
              sx={{ fontStyle: "italic", p: "0 1rem" }}
              variant="body2"
            >
              {t("materialreacttable.dblclick2edit")}
            </Typography>
          )}
          //enableRowNumbers
          //enableRowSelection
          //onEditRowSubmit={setRowSelection}
          localization={MRT_language}
          enableStickyHeader
          enableStickyFooter
          // initialState={{ columnVisibility: { id: false } }} //hide ID column by default
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ row, table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                //   color="secondary"
                onClick={(e) => {
                  downloadPdf(
                    queryresult.map((el) => [
                      el.id,
                      el.creationDate,
                      el.createdBy.name,
                      el.content,
                      el.priority,
                      el.duedate,
                      el.responsible.name,
                      el.recipientname,
                      el.status,
                      el.lastUpdateformated,
                    ])
                  );
                }}
                leftIcon={<FileDownloadIcon />}
                variant="light"
                compact
              >
                {t("materialreacttable.btnexport2pdf")}
              </Button>
              <Button
                //color="secondary"
                onClick={(e) => {
                  exportExcelFile(queryresult);
                }}
                //onClick={(e)  => exportToCSV(queryresult, "Combat Journal " + window.$name)}
                leftIcon={<FileDownloadIcon />}
                variant="light"
                tooltip={t("materialreacttable.ttexport2xlsx")}
                compact
              >
                {t("materialreacttable.btnexport2xlsx")}
              </Button>
              <Button
                onClick={() => table.resetColumnFilters()}
                starticon={<FilterAltOffIcon />}
                variant="light"
                compact
              >
                {t("materialreacttable.tclearfilter")}
              </Button>
            </Box>
          )}
        />
        <Modal
          centered
          opened={opened}
          onClose={() => close()}
          title={t("materialreacttable.modal.editcontent")}
          size="xl"
          mx={"xl"}
          m={"xl"}
          overlayProps={{
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[9]
                : theme.colors.gray[2],
            opacity: 0.55,
            blur: 3,
          }}
        >
          <Textarea
            type="text"
            defaultValue={selectedContent}
            id="outlined-multiline-static"
            onChange={(e) => debouncedHandleChange(e.target.value)}
            margin="5px"
            label={t("materialreacttable.modal.content")}
            withAsterisk
            minRows={4}
            maxRows={7}
            radius="md"
            mx="xl"
            rows={10}
            cols={50}
            m="xl"
            autosize
          />
          <Button
            size="sm"
            m={"sm"}
            compact
            mx={"xl"}
            onClick={handleSaveChanges}
            variant="outline"
          >
            {t("materialreacttable.modal.btnsave")}
          </Button>
        </Modal>
      </>
    );
  }
};

export default GetaskList;

import Process from 'components/process/Process';
import * as React from 'react';

import { useLoaderData } from "react-router-dom";

export function loader({params}){if("id" in params)return params.id;return null;}

export default function ProcessPage() {
    const id = useLoaderData();
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <Process id={id}/>
        </React.Suspense>
    );
}
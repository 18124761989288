
import { gql } from "@apollo/client";


export const MODIFY_ENTRY = gql`
mutation modMaximalJournalentry(
  $itemid: ID!
  $journalid: ID!
  $content: String!
  $createdBy: String!
  $followup: String
  $followupAt: DateTime!
){
      modifyJournalEntry(entry:{
        id: $itemid
        journal: $journalid
        createdBy:
            {
            name: $createdBy
          }
        content: $content
        followup: $followup
        followupAt: $followupAt
      })
      {
        id
        journal{
          id
        }
        createdAt
        content
        createdBy{name}
        followup
        followupAt
        followupBy{name}
        lastUpdate
        tags
      }
    }
    `; 
  export default MODIFY_ENTRY
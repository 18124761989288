import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { keys } from "lodash";

export default function ProcessTab({ handleTabClick }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [tabColors, setTabColors] = React.useState({
    LV: "light",
    PE: "light",
    BBD: "light",
    ENT: "light",
    PLE: "light",
    BF: "light",
    LV2: "light",
  });

  const tabs = [
    { name: "LV", value: "LV"},
    { name: "PE", value: "PE"},
    { name: "BBD", value: "BBD" },
    { name: "ENT", value: "ENT" },
    { name: "PLE", value: "PLE" },
    { name: "BF", value: "BF" },
    { name: "LV", value: "LV2" },
  ];

  const tabValueToIndex = {
    LV: 0,
    PE: 1,
    BBD: 2,
    ENT: 3,
    PLE: 4,
    BF: 5,
    LV2: 6,
  };

  const [value, setValue] = React.useState(tabs[0].value); 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleContextMenu = (event, tabValue) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setValue(tabValueToIndex[tabValue]);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeTabColor = (color) => {

    setTabColors({ ...tabColors, [value]: color, [keys]: keys });
    handleClose();
  };

  const handleTabSelection = (tabValue) => {
    handleTabClick(tabValue);
     setValue(tabValueToIndex[tabValue]);
   
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((tab, index) => (
          <Tooltip title={tab.name} key={index}>
            <Tab
              label={tab.name}
              value={tab.value} // Set the value from the tab object
              style={{ backgroundColor: tabColors[index] }}
              onContextMenu={(event) => handleContextMenu(event, tab.value)}
              onClick={() => handleTabSelection(tab.value)}
            />
          </Tooltip>
        ))}
      </Tabs>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => changeTabColor("red")}>Red</MenuItem>
        <MenuItem onClick={() => changeTabColor("blue")}>Blue</MenuItem>
        <MenuItem onClick={() => changeTabColor("green")}>Green</MenuItem>
      </Menu>
    </>
  );
}

import { gql } from "@apollo/client";

export const GET_PROCESSES_BY_OPID = gql`
  query GET_PROCESSES_BY_OPID($op: ID!) {
    getProcessesByOperationID(operation: $op) 
    {
        name
        order
        path
        clientPath
    }
  }
`;

export const GET_PROCESSITEMS_BY_OPERATIONIDTYPE = gql`
  query GET_PROCESSITEMS_BY_OPERATIONIDTYPE($op: ID!) {
    getProcessItemByOperationIDType(operation: $op, process: AP) 
    {
        id
        state
    }
  }
`;



export const SET_PROCESSITEM = gql`
  mutation SET_PROCESSITEM($op: ID!, $id: ID!, $state: ProcessItemState!) {
    setProcessItem(entry:{
      operation:$op
      process: AP
      id: $id
      state:$state
    }) {
      id
      state
    }
  }
`;

import { gql } from "@apollo/client";

export const GET_ALLJOURNAL = gql`
  query AllJournal {
    list: allJournal {
      id
      name
      state
      operation {
        id
        name
        state
      }
    }
  }
`;

export const ADD_JOURNAL = gql`
  mutation CreateJournal($name: String!) {
    newEntry: addJournal(journal: { name: $name }) {
      id
      name
      state
      operation {
        id
        name
        state
      }
    }
  }
`;

export const MOD_JOURNAL = gql`
  mutation ModJ($j: JournalInputMod!) {
    modifyJournal(journal: $j) {
      id
    }
  }
`;

export const LOAD_ENTRIES = gql`
  query LOAD_JOURNAL($journal: ID!) {
    getJournalEntryByJournalId(journal: $journal) {
      content
      id
      createdAt
      createdBy {
        name
      }
      followup
      followupAt
      followupBy {
        name
      }
      lastUpdate
    }
  }
`;

export const LOAD_JOURNALS = gql`
  query LOADJOURNAL {
    allJournal {
      id
      name
      state
      operation {
        id
        name
      }
    }
  }
`;

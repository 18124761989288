import * as React from 'react';
import Getconsequences from "../components/GsVb/GsVb"


export default function TasklistName() {
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <Getconsequences />
        </React.Suspense>
    );
}
import { gql } from "@apollo/client";

export const GET_ALLTASKLIST = gql`
  query AllTasklist {
    list: allTasklist {
      id
      name
      state
      operation {
        id
        name
        state
      }
    }
  }
`;

export const ADD_Tasklist = gql`
  mutation CreateJournal($name: String!) {
    newEntry: addTasklist(tasklist: { name: $name }) {
      id
      name
      state
      operation {
        id
        name
        state
      }
    }
  }
`;

export const MOD_TASKLIST = gql`
  mutation ModJ($t: TasklistInputMod!) {
    modifyTasklist(tasklist: $t) {
      id
    }
  }
`;

export const LOAD_ENTRIEST = gql`
  query getTasklistEntryByTasklistId($tasklist: ID!) {
    getTasklistEntryByTasklistId(tasklist: $tasklist) {
      content
      id
      createdAt
      createdBy {
        name
      }
      priority
      status
      lastUpdate
      due
      responsible {
        name
      }
      recipient {
        name
      }
    }
  }
`;

import { gql } from "@apollo/client";



export const CREATE_ENTRY = gql`
mutation addTasklistEntry($tasklistid: ID!,$content: String!, $priority: TasklistEntryPriority,$createdBy: String!, $status: TasklistEntryStatus, $due:DateTime,$responsible:String!,$recipient:String!){
  addTasklistEntry(entry:{
    tasklist:$tasklistid
    status : $status
    priority : $priority
    due : $due
    responsible :  {
      name: $responsible
    }
    recipient :  {
      name: $recipient
    }
    createdBy:
      {
        name: $createdBy
      }
    content: $content
  })
  {
    id
    createdAt
    content
    createdBy{name}
    status
    priority
    lastUpdate
    due
    responsible{name}
    recipient{name}
  }
}
`; 
  export default CREATE_ENTRY
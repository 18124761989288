import * as React from 'react';
import { useQuery } from '@apollo/client';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { GET_ALLOPERATION } from 'GraphQL/Operations';

export default function OperationsSelector(props) {
    let filterState = props.filterState || ["CLOSED"];
    let setOperation = props.onSetOperation || function (selectedOperation) { console.log(selectedOperation) };
    //request 
    const { loading, error, data } = useQuery(GET_ALLOPERATION);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :</p>;

    //Filter out unwanted (by default CLOSED are not shown)
    function isFiltered(operation) {
        //by State
        if (filterState.includes(operation.state)) {
            return true;
        }
        return false
    }


    let handleSelect = (selection) => setOperation(data.operations.find(o => o.id === selection.target.value));

    let value = props.value;
    if(data.operations.filter(e=>e.id===value).length===0)value = "";

    return (
        <div>
            <FormControl fullWidth id='op_selector'>
                <InputLabel htmlFor="grouped-select">Operation</InputLabel>
                <Select value={value} id="grouped-select" label="Operation" onChange={handleSelect}>
                    {data.operations.map(o => {
                        return(!isFiltered(o) ? <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem> : null)
                    })}
                </Select>
            </FormControl>
        </div>
    );
}
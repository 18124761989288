import * as React from 'react';
import { MetroSpinner } from "react-spinners-kit";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { NativeSelect, FormControl, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { useQuery, useMutation } from "@apollo/client";
// Là temporairement pour test 
import { modPE } from "GraphQL/ProjectManager";
import { GET_PROJECT } from "GraphQL/ProjectManager";
import { ADDPE } from 'GraphQL/ProjectManager';
import moment from "moment";





function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 0,
        bgcolor: 'background.default',
        color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
        border: '1px solid',
        borderColor: (theme) =>
          theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
        borderRadius: 1,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};


const MainEdit = (projectid,) => {
  const today = new Date().toISOString().split('T')[0];
  // Declare Variable
  const [description, setDescription] = useState('');
  const [projectNo, setProjectNo] = useState('');
  const [name, setName] = useState('');
  const [path, setPath] = useState('');
  const [projectLead, setProjectLead] = useState('');
  const [projectParticipants, setprojectParticipants] = useState('');
  const [supervisor, setsupervisor] = useState('');
  const [affected, setaffected] = useState('');
  const [cell, setCell] = useState('FGG0');
  const [presentation, setPresentation] = useState('');
  const [personelc, setpersonelc] = useState('');
  const [trainingc, settrainingc] = useState('')
  const [logisticc, setlogisticc] = useState('');
  const [managementc, setmanagementc] = useState('');
  const [personels, setpersonels] = useState('GREEN');
  const [trainings, settrainings] = useState('GREEN')
  const [logistics, setlogistics] = useState('GREEN');
  const [managements, setmanagements] = useState('GREEN');
  const [lastComment, setlastComment] = useState('');
  const [open, setopen] = useState("");
  const [statusfor, setstatusfor] = useState(today);
  const [hrl, setHrl] = useState('');
  const [hrl2ic, sethrl2ic] = useState('');




  function insertNewlines(str) {
    return str.match(/.{1,20}/g).join('\n');
  }

  // Modify Project Entry Mutation
  const [modifyProjectEntry, { datamodPE, loadingmodPE, errormodPE }] = useMutation(modPE);
  // ADD Project Entry Mutation
  const [addProjectEntry, { dataADDPE, loadingADDPE, errorADDPE }] = useMutation(ADDPE);
  // Load Project Entry Query
  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: { id: projectid.id },
  });

 
  useEffect(() => {
    if (projectid.id !== null && projectid.id !== "new") {
      const index = Number(projectid.id) - 1;
      if (data && data.allProjectEntry && data.allProjectEntry.length > 0) {
        setDescription(data.allProjectEntry[index].description);
        setName(data.allProjectEntry[index].name);
        setPath(data.allProjectEntry[index].path);
        setProjectLead(data.allProjectEntry[index].projectLead);
        setopen(data.allProjectEntry[index].open);
        setProjectNo(data.allProjectEntry[index].projectNo);
        //convert date, that the field can read the value
        const momentObject = moment(data.allProjectEntry[index].due);
        setstatusfor(momentObject.format("YYYY-MM-DD") || today);
        setCell(data.allProjectEntry[index].cell);
        setaffected(data.allProjectEntry[index].affected);
        setprojectParticipants(data.allProjectEntry[index].projectParticipants);
        setHrl(data.allProjectEntry[index].hrl);
        sethrl2ic(data.allProjectEntry[index].hrl2ic);
        setPresentation(data.allProjectEntry[index].presentation);
        setsupervisor(data.allProjectEntry[index].supervisor);
        setlastComment(data.allProjectEntry[index].lastComment);
        if (data.allProjectEntry[index].p) {
          setpersonelc(data.allProjectEntry[index].p.txt);
          setpersonels(data.allProjectEntry[index].p.state);
        }
        if (data.allProjectEntry[index].a) {
          settrainingc(data.allProjectEntry[index].a.txt);
          settrainings(data.allProjectEntry[index].a.state);
        }
        if (data.allProjectEntry[index].l) {
          setlogisticc(data.allProjectEntry[index].l.txt);
          setlogistics(data.allProjectEntry[index].l.state);
        }
        if (data.allProjectEntry[index].f) {
          setmanagementc(data.allProjectEntry[index].f.txt);
          setmanagements(data.allProjectEntry[index].f.state);
        }
        //  setlogisticc(data.allProjectEntry[index].l.txt);
        //  setfinancec(data.allProjectEntry[index].f.txt);
      }
    }
  }, [data, projectid.id]);



  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={80} color="black" loading={loading} />
      </div>
    );
  }
  ;
  if (loadingADDPE) {
    return (
      <div className="spinner">
        <MetroSpinner size={80} color="black" loading={loadingADDPE} />
      </div>
    );
  }
  ;
  if (error) {
    return (
      <div>
        <p>Error: {error.message}</p>
        {error.graphQLErrors.map((err, index) => (
          <p key={index}>GraphQL error: {err.message}</p>
        ))}
        {error.networkError && <p>Network error: {error.networkError.message}</p>}
      </div>
    );
  }

  console.log("data", dataADDPE)
  console.log("dataerror", errorADDPE)

  const handleback = () => {
    window.history.back();
  };

  //****************************** CREATE OR MODIFY PROJECT *************************************/
  //Fonction to modify the project entry
  const handlemodify = () => {
    // Check if the URL contains the word "new", create a new Project
    if (window.location.href.includes("new")) {
      //let maxId = Math.max(...data.allProjectEntry.map(project => project.id));
      //maxId = maxId + 1
      //  const due = new Date().toISOString().replace("Z", "");
      if (statusfor !== "") {
        const originalDate = new Date(statusfor);
        const momentObject = moment(originalDate);
        const newStatusFor = momentObject.format("YYYY-MM-DD");
        setstatusfor(newStatusFor);
      } else {
        setstatusfor(null);
      }
      const variables = { statusfor: statusfor && statusfor.length > 0 ? new Date(statusfor).toISOString().replace('Z', '') : null, path: path && path.length > 0 ? path : null, personels: personels && personels.length > 0 ? personels : null, personelc: personelc && personelc.length > 0 ? insertNewlines(personelc) : null, trainings: trainings && trainings.length > 0 ? trainings : null, trainingc: trainingc && trainingc.length > 0 ? insertNewlines(trainingc) : null, logistics: logistics && logistics.length > 0 ? logistics : null, logisticc: logisticc && logisticc.length > 0 ? insertNewlines(logisticc) : null, managementc: managementc && managementc.length > 0 ? insertNewlines(managementc) : null, managements: managements && managements.length > 0 ? managements : null, description: description && description.length > 0 ? insertNewlines(description) : null, projectLead: projectLead && projectLead.length > 0 ? projectLead : null, presentation: presentation && presentation.length > 0 ? presentation : null, affected, name, cell, hrl, hrl2ic, projectParticipants, supervisor, open, projectNo };
      console.log("variables", variables)
      addProjectEntry({
        variables,
        refetchQueries: [{ query: GET_PROJECT, variables: { id: projectid.id }, }]
      });
    }

    //Check when Project has a id
    const url = window.location.href;
    const lastChar = url.charAt(url.length - 1);
    if (!isNaN(lastChar)) {
      const variables = { id: Number(projectid.id), statusfor: statusfor && statusfor.length > 0 ? new Date(statusfor).toISOString().replace('Z', '') : null, path: path && path.length > 0 ? path : null, personels: personels && personels.length > 0 ? personels : null, personelc: personelc && personelc.length > 0 ? insertNewlines(personelc) : null, trainings: trainings && trainings.length > 0 ? trainings : null, trainingc: trainingc && trainingc.length > 0 ? insertNewlines(trainingc) : null, logistics: logistics && logistics.length > 0 ? logistics : null, logisticc: logisticc && logisticc.length > 0 ? insertNewlines(logisticc) : null, managementc: managementc && managementc.length > 0 ? insertNewlines(managementc) : null, managements: managements && managements.length > 0 ? managements : null, description: description && description.length > 0 ? insertNewlines(description) : null, projectLead: projectLead && projectLead.length > 0 ? projectLead : null, presentation: presentation && presentation.length > 0 ? presentation : null, affected, name, cell, hrl: hrl && hrl.length > 0 ? insertNewlines(hrl) : null, hrl2ic, projectParticipants, supervisor, open, projectNo, lastComment: lastComment && lastComment.length > 0 ? insertNewlines(lastComment) : null };
      console.log("variables", variables)
      modifyProjectEntry({
        variables,
        refetchQueries: [{ query: GET_PROJECT, variables: { id: projectid.id }, }]
      });
    }
    window.history.back();
    // window.location.href = "http://localhost:3000/Promanager";
  };
  //****************************** CREATE OR MODIFY PROJECT *************************************/


  if (loadingmodPE) return <p>Loading...</p>;
  if (errormodPE) {
    return (
      <div>
        <p>Error: {error.message}</p>
        {error.graphQLErrors.map((err, index) => (
          <p key={index}>GraphQL error: {err.message}</p>
        ))}
        {error.networkError && <p>Network error: {error.networkError.message}</p>}
      </div>
    );
  }
  console.log("datamodPE", datamodPE)

  /*  useEffect(() => {
   if (projectid.id !== undefined) {
     document.title = "ProjectEdit";
     navigate("/ProjectEdit/" + projectid.id);
   }
 }, [projectid.id, navigate]);  */



  //********* HANDLE SELECT STATUS ************************************/

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };
  //********* HANDLE SELECT STATUS ************************************/

  return (


    <div >


      <Grid container spacing={2}>
        <Grid item xs={6}>
          "Grid 1"
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-center',
              flexDirection: 'column',
              p: 0,
              m: 0,
              bgcolor: 'background.default',
              height: 300,
            }}
          >
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-center',
              p: 0,
              m: 0,
              bgcolor: 'background.default',
              height: 60,
            }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>

                <TextField
                  label="Project Name"
                  margin="normal"
                  required
                  size="small"
                  //  name="project Name"
                  id="projectname"
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{
                    maxLength: 30,
                    style: { fontSize: '1rem', width: "275PX", height: "50%" }
                  }}
                />
                <Item sx={{
                  display: 'flex', alignItems: 'center', border: 0,
                }}></Item>

                <TextField
                  label="path"
                  margin="normal"
                  required
                  size="small"
                  name="path"
                  id="path"
                  value={path || ""}
                  onChange={(e) => setPath(e.target.value)}

                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', width: "150PX", height: "50%" } }}
                /></Item>
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>

                <TextField
                  label="Project Number"
                  margin="normal"
                  required
                  size="small"
                  name="projectNb"
                  id="projectNb"
                  value={projectNo || ""}
                  onChange={(e) => setProjectNo(e.target.value)}

                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ maxLength: 5, style: { fontSize: '1rem', width: "90PX", height: "50%" } }}
                /></Item>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0,
                m: 0,
                backgroundColor: 'background.default',
                height: 110,
              }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', justifyContent: "flex-end", bgcolor: 'background.default', border: 0,
              }}>
                <form onSubmit={(e) => e.preventDefault()}>
                  <TextField
                    label="Description"
                    id="outlined-textarea"
                    value={description || ""}
                    onChange={(e) => setDescription(e.target.value)}
                    multiline
                    size="small"
                    maxRows={4}
                    minRows={4}
                    sx={{ bgcolor: 'background.default', marginTop: 2 }} // Add marginTop to move it down
                    inputProps={{ maxLength: 320, style: { fontSize: '1rem', width: "603px", overflowWrap: "break-word", wordBreak: "break-word" } }}
                  />
                </form>
              </Item>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0,
                m: 0,
                bgcolor: 'background.default',
                borderRadius: 1,
                height: 90,
              }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>
                <TextField
                  label="Project Participants"
                  margin="normal"
                  required
                  name="Participants"
                  id="Participants"
                  size="small"
                  value={projectParticipants || ""}
                  onChange={(e) => setprojectParticipants(e.target.value)}

                  sx={{ backgroundColor: 'background.default' }}
                  inputProps={{ maxLength: 50, style: { fontSize: '1rem', width: "400px" } }}

                /></Item>              <Item sx={{
                  display: 'flex', alignItems: 'center', border: 0,
                }}>
                <TextField
                  label="Point on Time"
                  margin="normal"
                  id="pointonTime"
                  size="small"
                  value={open || ""}
                  onChange={(e) => setopen(e.target.value)}
                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "0", width: "295px" } }}
                /></Item>

            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0.8,
                m: 0,
                bgcolor: 'background.default',
                borderRadius: 1,
                height: 70,
              }}
            >
              <Item sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'left', border: 0,
              }}>
                <span>FGG</span>
                <NativeSelect
                  value={cell}
                  onChange={e => setCell(e.target.value)}
                  id="cell-native-select"
                  sx={{ width: "100px", bgcolor: 'background.default' }}
                >
                  <option value="FGG0">FGG0</option>
                  <option value="FGG1">FGG1</option>
                  <option value="FGG2">FGG2</option>
                  <option value="FGG3">FGG4</option>
                  <option value="FGG4">FGG4</option>
                  <option value="FGG5">FGG5</option>
                  <option value="FGG6">FGG6</option>
                  <option value="FGG7">FGG7</option>
                  <option value="C Komm">C Komm</option>
                </NativeSelect>
              </Item>
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>
                <TextField
                  label="Project Lead"
                  margin="normal"
                  name="projectLead"
                  id="projectLead"
                  size="small"
                  value={projectLead || ""}
                  onChange={e => setProjectLead(e.target.value)}
                  maxLength={30}

                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', width: "250px" } }}
                /></Item>
              <Item sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'left', border: 0,
              }}>
                <span>Supervisor</span>
                <NativeSelect
                  value={supervisor || ""}
                  onChange={handleChange(setsupervisor)}
                  inputProps={{
                    name: 'supervisor',
                    id: 'supervisor-native-select',
                  }}
                  sx={{ width: "100px", bgcolor: 'background.default' }}
                >
                  <option value={'Kdt'}>Kdt</option>
                  <option value={'Kdt Stv'}>Kdt Stv</option>
                  <option value={'SC'}>SC</option>
                  <option value={'G1'}>G1</option>
                  <option value={'G2'}>G2</option>
                  <option value={'G3'}>G3</option>
                  <option value={'G4'}>G4</option>
                  <option value={'G5'}>G5</option>
                  <option value={'G6'}>G6</option>
                  <option value={'G7'}>G7</option>
                  <option value={'C Komm'}>C Komm</option>
                </NativeSelect>
              </Item>
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>
                <TextField
                  label="Affected"
                  margin="normal"
                  name="affected"
                  id="affected"
                  size="small"
                  value={affected || ""}
                  onChange={(e) => setaffected(e.target.value)}
                  maxLength={30}

                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "0", width: "250px" } }}
                /></Item>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0,
                m: 0,
                bgcolor: 'background.default',
                borderRadius: 1,
                height: 120,
                marginTop: 8, 
              }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>

                <TextField
                  label="Comment"
                  margin="normal"
                  name="commentlastsk"
                  id="commentlastsk"
                  size="small"
                  value={lastComment || ""}
                  onChange={(e) => setlastComment(e.target.value)}
                  minRows={4}
                  multiline
                  maxRows={4}
                  maxLength={320}
                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "0", width: "600px" } }}
                />
              </Item>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0,
                m: 0,
                bgcolor: 'background.default',
                borderRadius: 1,
                height: 80,
                border: 0,
              }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0, marginBottom: -6,
              }}>
                <TextField
                  label="HRL Cdt"
                  margin="normal"
                  name="hrlcdt"
                  id="hrlcdt"
                  size="small"
                  value={hrl || ""}
                  onChange={(e) => setHrl(e.target.value)}
                  multiline
                  maxLength={160}
                  minrows={2}
                  maxRows={4}
                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "0", width: "400px" } }}
                />
              </Item>
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0, marginBottom: -6, 
              }}>
                <TextField
                  label="Hrl Stabchef"
                  margin="normal"
                  name="hrl Stabchef"
                  id="hrl Stabchef"
                  size="small"
                  value={hrl2ic || ""}
                  onChange={(e) => sethrl2ic(e.target.value)}
                  multiline
                  maxLength={160}
                  minrows={2}
                  maxRows={4}
                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "0", width: "400px" } }}
                />
              </Item>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          "Grid 2"
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-center',
              p: 1,
              m: 1,
              bgcolor: 'background.default',
              height: 80,
            }}
          >
            <div style={{ width: '100%' }}>
              <Grid container direction="column" spacing={2}>
                <Grid item container spacing={2}>
                  <Grid item>
                    <FormControl>
                      <label>Status Personnel</label>
                      <Box bgcolor={personels === '' ? 'red' : personels.toLowerCase()}>
                        <NativeSelect
                          value={personels || ""}
                          onChange={handleChange(setpersonels)}
                        >
                          <option value={'GREEN'}>No need for action</option>
                          <option value={'YELLOW'}>Middle need for action</option>
                          <option value={'RED'}>Major need for action</option>
                        </NativeSelect>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      style={{ marginTop: '20px' }}
                      id="outlined-textarea"
                      label="Comment personnel"
                      value={personelc || ""}
                      size="small"
                      onChange={(e) => setpersonelc(e.target.value)}
                      multiline
                      maxLength={160}
                      rows={3}
                      sx={{ bgcolor: 'background.default' }}
                      inputProps={{ style: { fontSize: '1rem', width: "400px" } }}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    <FormControl>
                      <label>Status Training</label>
                      <Box bgcolor={trainings}>
                        <NativeSelect
                          value={trainings || ""}
                          onChange={handleChange(settrainings)}
                        >
                          <option value={'GREEN'}>No need for action</option>
                          <option value={'YELLOW'}>Middle need for action</option>
                          <option value={'RED'}>Major need for action</option>
                        </NativeSelect>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      style={{ marginTop: '20px' }}
                      id="outlined-textarea"
                      label="Comment Training"
                      value={trainingc || ""}
                      size="small"
                      onChange={(e) => settrainingc(e.target.value)}
                      multiline
                      maxLength={160}
                      rows={3}
                      sx={{ bgcolor: 'background.default' }}
                      inputProps={{ style: { fontSize: '1rem', width: "400px" } }}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>
                    <FormControl>
                      <label>Status Logistic</label>
                      <Box bgcolor={logistics}>
                        <NativeSelect
                          value={logistics || ""}
                          onChange={handleChange(setlogistics)}
                        >
                          <option value={'GREEN'}>No need for action</option>
                          <option value={'YELLOW'}>Middle need for action</option>
                          <option value={'RED'}>Major need for action</option>
                        </NativeSelect>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      style={{ marginTop: '20px' }}
                      id="outlined-textarea"
                      label="Comment Logistic"
                      size="small"
                      value={logisticc || ""}
                      onChange={(e) => setlogisticc(e.target.value)}
                      multiline
                      maxLength={160}
                      rows={3}
                      sx={{ bgcolor: 'background.default' }}
                      inputProps={{ style: { fontSize: '1rem', width: "400px" } }}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item>

                    <FormControl>
                      <label>Status Management</label>
                      <Box bgcolor={managements}>
                        <NativeSelect
                          value={managements || ""}
                          onChange={handleChange(setmanagements)}
                        >
                          <option value={'GREEN'}>No need for action</option>
                          <option value={'YELLOW'}>Middle need for action</option>
                          <option value={'RED'}>Major need for action</option>
                        </NativeSelect>
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <TextField
                      margin="normal"
                      id="outlined-textarea"
                      label="Comment Management"
                      value={managementc || ""}
                      onChange={(e) => setmanagementc(e.target.value)}
                      multiline
                      maxLength={160}
                      rows={3}
                      sx={{ bgcolor: 'background.default' }}
                      inputProps={{ style: { fontSize: '1rem', width: "400px" } }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-center',
              p: 0,
              m: 0,
              bgcolor: 'background.default',
              height: 60,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0,
                m: 0,
                bgcolor: 'background.default',
                borderRadius: 1,
                height: 120,
              }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>
                <TextField
                  label="Status for"
                  margin="normal"
                  id="statusfor"
                  size="small"
                  type="date"
                  value={statusfor || ""}
                  onChange={(e) => setstatusfor(e.target.value)}

                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "0", width: "120px" } }}
                />
              </Item>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                p: 0,
                m: 0,
                bgcolor: 'background.default',
                borderRadius: 1,
                height: 120,
              }}
            >
              <Item sx={{
                display: 'flex', alignItems: 'center', border: 0,
              }}>
                <TextField
                  label="Next presentation"
                  margin="normal"
                  name="nextp"
                  id="nextp"
                  size="small"
                  value={presentation || ""}
                  onChange={(e) => setPresentation(e.target.value)}
                  maxLength={30}
                  sx={{ bgcolor: 'background.default' }}
                  inputProps={{ style: { fontSize: '1rem', margin: "1", width: "250px" } }}
                />
              </Item>
            </Box>
            <Grid item xs={6}>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flexend',
                  p: 1,
                  m: 0,
                  bgcolor: 'background.default',
                  height: 80,
                }}
              >
              </Box>
            </Grid>
          
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flexend',
              p: 1,
              m: 0,
              bgcolor: 'background.default',
              height: 80,
            }}
          >
          </Box>
        </Grid>
        
      </Grid>
      
      <Grid container spacing={2}>
        <Grid item xs={12}>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-center',
              p: 1,
              m: 0,
              bgcolor: 'background.default',
              height: 40,
              marginTop: 35,
            }}
          >
            {/* Your code for Grid 5 */}
            <Button variant="contained" color="primary" onClick={handleback} style={{ marginRight: '10px' }}>
              Back
            </Button>
            <Button variant="contained" disabled={name.length < 1} color="primary" onClick={handlemodify} >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div >
  );
}
export default MainEdit;
import { useMutation } from "@apollo/client";
import { IconButton, Tooltip} from "@mui/material";
import { Modal, Button, TextInput, useMantineTheme , Group } from '@mantine/core';
import React, { useState } from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useTranslation } from "react-i18next";
import { ADD_Tasklist, GET_ALLTASKLIST } from "GraphQL/Tasklists";

export default function AddTasklist(props) {
  let setCallback = props.onSet || function (newEntry) { console.log(newEntry) };
  const [CreateList, { loading, error}] = useMutation(ADD_Tasklist);
  const [open, setOpen] = useState(false);
  const [elName, setName] = useState("");
  const theme = useMantineTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { t } = useTranslation("admin");

  function close(){
    setOpen(false);
    setName("");
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      save()
  }};

  function save() {
    if (isValidName) {
      CreateList({
        variables: {
          name: elName
        },
        refetchQueries: [{ query: GET_ALLTASKLIST }],
        onCompleted: (data)=>{
          if(!error){
            close();
            setCallback(data.newEntry);
          }
        }
      });

    }
  }

  function isValidName() {
    if (elName.length < 3) return false;
    return true;
  }

  return (
    <React.Fragment>
      <Tooltip title={t("tooltip.addtasklist")}>
        <IconButton className="btn" onClick={handleClickOpen}>
          <NoteAddIcon />
        </IconButton>
      </Tooltip>

  {/*     <Dialog open={open} onKeyDown={handleKeyDown} onClose={() => setOpen(false)}>
        <DialogTitle>{t("dialog.newtasklist.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("dialog.newtasklist.description")}</DialogContentText>
          <TextField
            autoFocus
            className="BtnNewItem"
            color="secondary"
            margin="dense"
            id="name"
            label={t("dialog.newtasklist.lblname")}
            type="name"
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
            {(loading) ? <DialogContentText>Submitting...</DialogContentText>:null}
            {(error) ? <DialogContentText>Submission error!<br/>{error.message}</DialogContentText> :null}
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{t("dialog.btncancel")}</Button>
          <Button onClick={save} disabled={!isValidName()}>{t("dialog.btnsave")}</Button>
        </DialogActions>
      </Dialog> */}
      <Modal 
        opened={open} 
        onClose={() => setOpen(false)}
        onKeyDown={handleKeyDown}
        title={t("dialog.newtasklist.title")}
        size="xl"  
        centered
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
        >
        <TextInput 
         label={t("dialog.newtasklist.lblname")}
         placeholder={t("dialog.newtasklist.lblname")}
         type="name"
         onChange={(e) => setName(e.target.value)}
         data-autofocus
         withAsterisk
         mx={"sm"}
         m={"sm"}      
         />
          {(loading) ? <Modal>Submitting...</Modal>:null}
          {(error) ? <Modal>Submission error!<br/>{error.message}</Modal> :null}
          <Group mt="xl">
          <Button  mx={"xl"} onClick={close}>{t("dialog.btncancel")}</Button>
          <Button  mx={"xl"} onClick={save} disabled={!isValidName()}>{t("dialog.btnsave")}</Button>
        </Group>
      </Modal>
    </React.Fragment>
  );
}

import { useQuery } from '@apollo/client';
import { GET_PROCESSES_BY_OPID } from "GraphQL/Process";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from 'react';
import { Alert, Tooltip } from '@mui/material';
import { useTranslation } from "react-i18next";


export default function ProcessTab(props) {
  let onSetProcess = props.onSetProcess || function (selectedProcess) { console.log(selectedProcess) };
  let op = props.op
  const { t, i18n } = useTranslation("process");

  let [selectedProcess, setSelectedProcess] = useState();

  function handleChange(e, newValue) {
    setSelectedProcess(newValue);
    onSetProcess(processes[newValue]);
  }

  //request 
  const { loading, error, data } = useQuery(GET_PROCESSES_BY_OPID, { variables: { op: op.id } });
  let processes = [];

  if (data) {
    processes = data.getProcessesByOperationID.slice().sort((a, b) => a.order - b.order);

    //filter out processes that are not in the correct language. This should be temporary until we have a better solution over OpMgmt
    if(i18n.language === "fr"){
      processes = processes.filter((process) => process.path.includes("_f"));
    } else {
      processes = processes.filter((process) => !process.path.includes("_f"));
    }


    if(selectedProcess === undefined) handleChange(null, 0);
    
    return (
      <Tabs onChange={handleChange} value={selectedProcess}>
        {processes.map((process, index) => (
          <Tab label={process.name} value={index} key={index} />
        ))}
      </Tabs>
    );
  }
  if (loading) return <p>Loading...</p>;

  if (error) {
    return (
      <Tooltip title={error.message}>
        <Alert severity="error">{t("error_processload")}</Alert>
      </Tooltip>
    );
  }
}

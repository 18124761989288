import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { MetroSpinner } from "react-spinners-kit";
import { darken } from "@mui/material";
//Import Material React Table Translations
//import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { Typography } from "@mui/material";
//****************** ADD ENTRY ******************************/
import MODIFY_CONTENT from "GraphQL/ModifyJournalContent";
//****************** ADD ENTRY ******************************/

//****************** IMPORT GQL ****************************/
import MODIFY_ENTRY from "GraphQL/ModifyJournalEntry";
import MODIFY_JEflwBy from "GraphQL/ModifyJournalFlwBy";
//****************** IMPORT GQL ****************************/

//************************ ICON ****************************/
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
//import BackupIcon from "@mui/icons-material/Backup";
//import RestoreIcon from "@mui/icons-material/Restore";
//************************ ICON ****************************/
import logo from "pictures/bundlogo.png";
import { useDisclosure } from "@mantine/hooks";
import { useQuery,  useMutation } from "@apollo/client";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button, Box, Textarea, Modal, useMantineTheme } from "@mantine/core";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { LOAD_ENTRIES } from "GraphQL/Journals";

const GetEntriesTable = (journal, refresh) => {
  //**************************** DECLARE CONST *****************************************/
  const [opened, { open, close }] = useDisclosure(false);
  const [idcontent, setidcontent] = useState(null);
  const theme = useMantineTheme();
  const [selectedContent, setSelectedContent] = useState("");
  const [modJournal] = useMutation(MODIFY_ENTRY);
  const [modContent] = useMutation(MODIFY_CONTENT);
  const [modMJefollowBY] = useMutation(MODIFY_JEflwBy);
  const { t, i18n } = useTranslation("journal");
  const ExcelJS = require("exceljs");
  let MRT_language = "";

  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(LOAD_ENTRIES, {
    variables: { journal: journal.journal },
    pollInterval: journal.refresh * 1000,
  });

  //**************************** HANDLE MODAL *****************************************/
  const handleContentClick = (row) => {
    // Set the value of content and itemid
    setSelectedContent(row.original.content);
    setidcontent(row.original.id);
    open();

    // setIsModalOpen(true);
  };

  // Debounce the input handling function
  const debouncedHandleChange = debounce((value) => {
    setSelectedContent(value);
  }, 700); // Adjust the debounce delay as needed

  const handleSaveChanges = () => {
    modContent({
      variables: {
        itemid: idcontent,
        journalid: window.journalid,
        content: selectedContent,
      },
      refetchQueries: [
        {
          query: LOAD_ENTRIES,
          refetchQueries: [
            {
              query: LOAD_ENTRIES,
              variables: { journal: journal.journal },
            },
          ],
        },
      ],
    });
    close();
  };
  //**************************** HANDLE MODAL *****************************************/

  //******************************** Export to PDF  *****************************************************************/
  const downloadPdf = (data) => {
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = logo;

    doc.setFontSize(7.5);
    doc.text(
      window.$name,
      doc.internal.pageSize.getWidth() / 2,
      35,
      null,
      null,
      "center"
    );

    doc.addImage(img, "png", 2, 2, 200, 50);

    doc.setFontSize(7.5);
    doc.text(t("getjournals.headerdepartemenrt1"), 600, 15);
    doc.text(t("getjournals.headerdepartemenrt2"), 600, 27);
    doc.text(t("getjournals.headerarmee"), 600, 39);

    const tableConfig = {
      theme: "striped",
      showHead: "everyPage",
      margin: { top: 60, right: 14, bottom: 50, left: 14 },
      cellWidth: "wrap",
      columnStyles: {
        0: { cellWidth: 23 },
        1: { cellWidth: 80 },
        2: { cellWidth: 140 },
        3: { cellWidth: 160 },
        4: { cellWidth: 70 },
        5: { cellWidth: 160 },
        6: { cellWidth: 70 },
        7: { cellWidth: 85 },
        8: { cellWidth: 95 },
      },
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: data,
    };

    doc.autoTable(tableConfig);

    var i = "";
    var pageCount = doc.internal.getNumberOfPages();
    for (i = 0; i < pageCount; i++) {
      doc.setPage(i);
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber;
      doc.text(t("tablelibrary.page") + pageCurrent + "/" + pageCount, 20, 550);
    }

    doc.save(window.$name + ".pdf");
  };
  //******************************** Export to PDF  *****************************************************************/

  const handleSaveCell = (cell, value) => {
    let contentdata = "";
    let createdBynamedata = "";
    let followupBynamedata;
    let followupdata;
    let iddata = data.getJournalEntryByJournalId[cell.row.index].id;

    //*** SET CONTENT ***/
    if (cell.column.id === "content") {
      contentdata = value;
    } else {
      contentdata = data.getJournalEntryByJournalId[cell.row.index].content;
    }
    //*** SET CONTENT ***/

    //*** SET CREATEDBYNAME ***/
    if (cell.column.id === "createdByname") {
      createdBynamedata = value;
    } else {
      createdBynamedata =
        data.getJournalEntryByJournalId[cell.row.index].createdBy.name;
      if (createdBynamedata === null) {
        createdBynamedata = null;
      }
    }
    //*** SET CREATEDBYNAME ***/

    //*** SET FOLLOWUPAT ***/
    //Change the Date format for use with Graphql
    //const formatted = new Date().toISOString().replace("Z", "");
    const datefollow = new Date();
    let followat =
      datefollow.getFullYear() +
      "-" +
      String(datefollow.getMonth() + 1).padStart(2, "0") +
      "-" +
      String(datefollow.getDate()).padStart(2, "0") +
      "T" +
      String(datefollow.getHours()).padStart(2, "0") +
      ":" +
      String(datefollow.getMinutes()).padStart(2, "0") +
      ":" +
      String(datefollow.getSeconds()).padStart(2, "0");

    if (
      (followupdata === null && followupBynamedata === "") ||
      (followupBynamedata === null && followupdata === "")
    ) {
      followat = null;
    }
    //*** SET FOLLOWUPAT ***/
    //*** SET FOLLOWUPBYNAME ***/

    if (cell.column.id === "followupByname") {
      let followupBynamedata = value;
      modMJefollowBY({
        variables: {
          itemid: iddata,
          journalid: journal.journal,
          content: data.getJournalEntryByJournalId[cell.row.index].content,
          createdBy:
            data.getJournalEntryByJournalId[cell.row.index].createdBy.name,
          followupBy: followupBynamedata,
          followupAt: followat,
        },
        refetchQueries: [
          { query: LOAD_ENTRIES, variables: { journal: journal.journal } },
        ],
      });
    }

    //*** SET FOLLOWUPBYNAME ***/

    //*** SET FOLLOWUP ***/

    if (cell.column.id === "followup") {
      followupdata = value;
    } else {
      followupdata = data.getJournalEntryByJournalId[cell.row.index].followup;
      if (followupdata === null) {
        followupdata = null;
      }
    }

    //*** SET FOLLOWUP ***/
    if (followupBynamedata === undefined || followupBynamedata === "") {
      followupBynamedata = null;
    }

    // Use  mutation to the Grapqhql Database

    if (cell.column.id !== "followupByname" && journal.journal !== null) {
      modJournal({
        variables: {
          itemid: iddata,
          journalid: journal.journal,
          content: contentdata,
          createdBy: createdBynamedata,
          // followupBy: followupBynamedata, // Modify the nullable string variable
          followup: followupdata,
          followupAt: followat,
        },
        refetchQueries: [
          { query: LOAD_ENTRIES, variables: { journal: journal.journal } },
        ],
      });
    }
  };

  //if (errormod) console.log("error", errormod);
  // ****************************** DBL CLICK EDIT / SAVE ***********************************************************/

  const columns = useMemo(
    () => [
      // HEADER
      /*      {
        id: 'headerOrdre', //id used to define `group` column
       // header: 'Ereignis / Befehl    Evénement / Ordre     Avvenimento / Ordine',
        header: t("ordre"),
     //   columns: [ */
      {
        accessorKey: "id",
        header: "id",
        minSize: 1, //min size enforced during resizing
        maxSize: 3, //max size enforced during resizing
        size: 3, //medium column
        enableEditing: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "creationDate", //simple recommended way to define a column
        header: t("tablelibrary.creationdate"),
        enableEditing: false,
        minSize: 8, //min size enforced during resizing
        maxSize: 12, //max size enforced during resizing
        size: 10, //medium column
        style: { whiteSpace: "wrap" }, //Add this line to the column definition
        enableClickToCopy: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "createdByname",
        header: t("tablelibrary.creator"),
        minSize: 8, //min size enforced during resizing
        maxSize: 25, //max size enforced during resizing
        size: 10, //medium column
        style: { whiteSpace: "wrap" }, //Add this line to the column definition
      },
      {
        accessorKey: "content",
        header: t("tablelibrary.content"),
        maxsize: 50,
        size: 30,
        enableEditing: false,
        enableClickToCopy: false,
        Cell: ({ cell, row, column }) => (
          <span
            style={{ whiteSpace: "pre-line" }}
            onDoubleClick={() => handleContentClick(row)}
          >
            <div onDoubleClick={() => handleContentClick(row)}>
              {cell.renderValue()}
            </div>
          </span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          variant: "outlined",
          multiline: true,
        }),
      },
      {
        accessorKey: "followupByname", //simple recommended way to define a column
        header: t("tablelibrary.followupBy"),
        size: 50,
      },
      {
        accessorKey: "followup", //simple recommended way to define a column
        header: t("tablelibrary.followup"),
        size: 50,
        Cell: ({ cell }) => (
          <span style={{ whiteSpace: "pre-line" }}>{cell.renderValue()}</span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          variant: "outlined",
          multiline: true,
        }),
      },
      {
        accessorKey: "followupAt", //simple recommended way to define a column
        header: t("tablelibrary.FollowTime"),
        minSize: 8, //min size enforced during resizing
        maxSize: 12, //max size enforced during resizing
        size: 10, //medium column
        enableEditing: false,
        maxsize: 30,
        enableClickToCopy: false,
      },
      {
        accessorKey: "lastUpdate", //simple recommended way to define a column
        header: t("tablelibrary.lastupdate"),
        enableEditing: false,
        minSize: 8, //min size enforced during resizing
        maxSize: 12, //max size enforced during resizing
        size: 10, //medium column
        maxsize: 30,
        enableClickToCopy: false,
        enableColumnFilter: false,
      },
    ],
    /*   },
    ], */
    [t]
  );
  // ******************************************** Define Colums for Table *************************************************************

  //************************ NEW EXPORT CSV **************************************** */
  const exportExcelFile = (dataforexecel) => {
    const workbook = new ExcelJS.Workbook();

    var sheet = workbook.addWorksheet(window.$name, {
      pageSetup: {
        horizontalCentered: true,
        paperSize: 9,
        orientation: "landscape",
        margins: {
          left: 0.3,
          right: 0.3,
          top: 0.3,
          bottom: 0.3,
          header: 0.3,
          footer: 0.2,
        },
      },
    });
    sheet.getRow(1).height = 30;
    sheet.pageSetup.printTitlesColumn = "1:1";
    sheet.autoFilter = {
      from: "A1",
      to: "H1",
    };

    sheet.headerFooter.oddFooter = "Page &P of &N";

    const columnsStyle = { font: { name: "Arial Black", size: 10 } };

    //Define Headers Colums and set Backgroundcolor
    ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1"].forEach((key) => {
      const cell = sheet.getCell(key);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00000000" },
        bgColor: { argb: "96C8FB" },
      };
    });
    //Define fonc option
    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 13,
      bold: true,
      font: { color: { argb: "#FFFFFF" } },
      color: { argb: "FFFFFF" },
    };

    sheet.columns = [
      {
        header: "Id",
        key: "id",
        width: 4,
      },
      {
        header: t("tablelibrary.creationdate"),
        key: "creationDate",
        width: 15,
      },
      {
        header: t("tablelibrary.creator"),
        key: "createdByname",
        width: 18,
      },
      {
        header: t("tablelibrary.content"),
        key: "content",
        width: 27,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.followupBy"),
        key: "followupByname",
        width: 15,
      },
      {
        header: t("tablelibrary.followup"),
        key: "followup",
        width: 25,
      },
      {
        header: t("tablelibrary.FollowTime"),
        key: "followupAt",
        width: 14,
        /*       style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        }, */
      },
      {
        header: t("tablelibrary.lastupdate"),
        key: "lastUpdate",
        width: 15,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
    ];

    dataforexecel.forEach((element) => {
      sheet.addRow({
        id: element.id,
        content: element.content,
        creationDate: element.creationDate,
        createdByname: element.createdByname,
        followup: element.followup,
        followupAt: element.followupAt,
        followupByname: element.followupByname,
        lastUpdate: element.lastUpdate,
      });
    });

    //Generate the Xlsx file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = window.$name + ".xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  //********************************* EXPORT CSV **************************************************************************/
  if (error) {
    console.log({ error });
  }
  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={80} color="black" loading={loading} />
      </div>
    );
  }

  if (data) {
    //console.log("Result Query array", data);
    let queryresult = data.getJournalEntryByJournalId.map((element) => {
      let e = structuredClone(element);
      e.createdByname = element.createdBy.name;
      if (
        element.createdAt !== null &&
        element.createdAt !== undefined &&
        element.createdAt !== ""
      ) {
        e.creationDate =
          element.createdAt.slice(8, 10) +
          "." +
          element.createdAt.slice(5, 7) +
          "." +
          element.createdAt.slice(2, 4) +
          " " +
          element.createdAt.split("T")[1].slice(0, 5);
      }

      if (
        element.followupAt !== null &&
        element.followupAt !== undefined &&
        element.followupAt !== ""
      ) {
        e.followupAt =
          element.followupAt.slice(8, 10) +
          "." +
          element.followupAt.slice(5, 7) +
          "." +
          element.followupAt.slice(2, 4) +
          " " +
          element.followupAt.split("T")[1].slice(0, 5);
      }

      if (
        element.lastUpdate !== null &&
        element.lastUpdate !== undefined &&
        element.lastUpdate !== ""
      ) {
        e.lastUpdate =
          element.lastUpdate.slice(8, 10) +
          "." +
          element.lastUpdate.slice(5, 7) +
          "." +
          element.lastUpdate.slice(2, 4) +
          " " +
          element.lastUpdate.split("T")[1].slice(0, 5);
      }

      if (
        element.followupBy !== undefined &&
        element.followupBy !== "" &&
        element.followupBy !== null
      ) {
        e.followupByname = element.followupBy.name;
      } else {
        e.followupByname = "";
      }

      return e;
    });
    //console.log("queryresult", queryresult);

    return (
      <>
        <MaterialReactTable
          columns={columns}
          data={queryresult}
          editingMode="cell"
          enableEditing
          //DT201 ADD edit on doubleclick
          // onEditingRowChange={handleSaveRow}
          muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value, row);
            },
          })}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.1),
              },
            }),
          }}
          //DT201 ADD edit on doubleclick
          enableColumnOrdering
          enableStickyHeader
          enableColumnResizing
          enableClickToCopy
          enableColumnFilterChangeMode
          enableFullScreenToggle={false}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
          }}
          maxsize="100%"
          options={{}}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                // justifyContent: "space-between",
                align: "center",
                // backgroundColor: 'gray',
              },
            },
          }}
          renderBottomToolbarCustomActions={() => (
            <Typography
              sx={{ fontStyle: "italic", p: "0 1rem" }}
              variant="body2"
            >
              {t("tablelibrary.dblclick2edit")}
            </Typography>
          )}
          //enableRowNumbers
          //enableRowSelection
          //onEditRowSubmit={setRowSelection}
          localization={MRT_language}
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ row, table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={(e) => {
                  downloadPdf(
                    queryresult.map((el) => [
                      el.id,
                      el.creationDate,
                      el.createdBy.name,
                      el.content,
                      el.followupByname,
                      el.followup,
                      el.followupAt,
                      el.lastUpdate,
                    ])
                  );
                }}
                leftIcon={<FileDownloadIcon />}
                tooltip={t("tablelibrary.ttexport2pdf")}
                compact
                variant="light"
              >
                {t("tablelibrary.btnexport2pdf")}
              </Button>
              <Button
                //  color="secondary"
                onClick={(e) => {
                  exportExcelFile(queryresult);
                }}
                compact
                leftIcon={<FileDownloadIcon />}
                tooltip={t("tablelibrary.ttexport2xlsx")}
                variant="light"
              >
                {t("tablelibrary.btnexport2xlsx")}
              </Button>
              <Button
                onClick={() => table.resetColumnFilters()}
                leftIcon={<FilterAltOffIcon />}
                compact
                variant="light"
                tooltip={t("tablelibrary.tClearFilter")}
              >
                {t("tablelibrary.tclearfilter")}
              </Button>
            </Box>
          )}
        />
        <Modal
          centered
          opened={opened}
          onClose={() => close()}
          title={t("tablelibrary.modal.editcontent")}
          size="xl"
          mx={"xl"}
          m={"xl"}
          overlayProps={{
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[9]
                : theme.colors.gray[2],
            opacity: 0.55,
            blur: 3,
          }}
        >
          <Textarea
            type="text"
            defaultValue={selectedContent}
            id="outlined-multiline-static"
            onChange={(e) => debouncedHandleChange(e.target.value)}
            margin="5px"
            label={t("tablelibrary.modal.content")}
            withAsterisk
            minRows={4}
            maxRows={7}
            radius="md"
            mx="xl"
            rows={10}
            cols={50}
            m="xl"
            autosize
          />
          <Button
            size="sm"
            m={"xl"}
            compact
            mx={"xl"}
            onClick={handleSaveChanges}
            variant="outline"
          >
            {t("tablelibrary.modal.btnsave")}
          </Button>
        </Modal>
      </>
    );
  }
};

export default GetEntriesTable;

import * as React from 'react';
import { Outlet } from "react-router-dom";
import STNGAppBar from "../components/STNGAppBar";

export default function Root() {
    return (
        <React.Fragment>
            <STNGAppBar />
            <React.Suspense fallback={<div>loading...</div>}>
                <Outlet />
            </React.Suspense>
        </React.Fragment>
    )
}

import { gql } from "@apollo/client";


export const MODIFY_ENTRY = gql`
mutation modMaximalTasklistEntry(
  $itemid: ID!
  $journalid: ID!
  $content: String!
  $creator : String!
  $status: TasklistEntryStatus
  $priority: TasklistEntryPriority
  $responsible : String!
  $recipient : String!
  $due : DateTime
){
      modifyTasklistEntry(entry:{
        id: $itemid
        tasklist: $journalid
        createdBy:
            {
            name: $creator
          }
          responsible:
          {
          name: $responsible
        }
        recipient:
          {
          name: $recipient
        }
        content: $content
        status: $status
        priority: $priority
        due: $due
      })
      {
        id
        tasklist{
          id
        }
        createdAt
        content
        createdBy{name}
        priority
        status
        due
      }
    }
      `; 
  export default MODIFY_ENTRY
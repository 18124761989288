import {
    ApolloClient,
    InMemoryCache,
    HttpLink,
    from,
  } from "@apollo/client";

let link = from([new HttpLink({ uri: "/graphql" })]);
if ( process.env.NODE_ENV === 'development') {
  link = from([new HttpLink({ uri: "https://localhost:8080/graphql" })]);
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

export {client};
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, TextField, Stack, Button } from "@mui/material";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import version from "config/version.json";

export default function AdminPage() {
  const { t } = useTranslation("about");

  return (
    <React.Suspense fallback={<div>loading...</div>}>
      <Box className="content">
        <Stack direction={"row"} marginTop={"2em"} columnGap={"2em"}>
          <TextField
            disabled
            label={t("lbl_version")}
            value={version.version}
          />
          <TextField disabled label={t("lbl_build")} value={version.build} />
          <TextField
            disabled
            label={t("lbl_builddate")}
            value={version.builddatetime}
          />
        </Stack>
        <Box marginTop={"2em"}>
          <Typography variant="h6">{t("info_paragraph_1_title")}</Typography>
          <Typography variant="body1">{t("info_paragraph_1a")}</Typography>
          <Stack direction={"row"} marginTop={"2em"} columnGap={"2em"}>
            <a href="/PFT_d.pdf" target="_blank">
              <Button
                component="label"
                variant="contained"
                startIcon={<HelpCenterIcon />}
              >
                {t("manual_d")}
              </Button>
            </a>
            <a href="/PFT_d.pdf" target="_blank">
              <Button
                component="label"
                variant="contained"
                startIcon={<HelpCenterIcon />}
              >
                {t("manual_f")}
              </Button>
            </a>
          </Stack>
        </Box>
        <Box marginTop={"2em"}>
          <Typography variant="h6">{t("info_paragraph_2_title")}</Typography>
          <Typography variant="body1">{t("info_paragraph_2a")}</Typography>
          <Typography variant="body1">{t("info_paragraph_2b")}</Typography>
          <a href="/log/PFT.log" target="_blank">
            <Typography variant="body1">{t("logs")}</Typography>
          </a>
        </Box>
      </Box>
    </React.Suspense>
  );
}

import { useQuery } from "@apollo/client";
import { useState, useEffect, useRef } from "react";
import OperationsSelector from "components/OperationsSelector";
//import { Select } from "@mantine/core";
import { Stack, Grid, FormControl, Button } from "@mui/material";
//use after for know which operation is selected
import GetEntriesTable from "./ConsequenceEntry";
import { LOAD_OPERATIONS } from "GraphQL/GetAllOperation";
import { AddCircleOutline } from "@mui/icons-material"; // Importer l'icône d'ajout de cercle

//import AddEntry from "components/consequences/AddEntry";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ProcessTag from "./ProcessTab"; // Import the navigation icon component


window.journalid = "";
window.$name = "";

//**************************** DECLARE CONST *****************************************/
const Getconsequences = (props) => {
  let filterState = props.filterState || ["CLOSED"];
  const { loading, error, data } = useQuery(LOAD_OPERATIONS);
  const [selectedOp, setSelectedOp] = useState("");
  const [selectedProcess, setSelectedProcess] = useState("");
  const { t } = useTranslation("consequence");
  const navigate = useNavigate();

  // const containerRef = useRef(null);
  function navigateTo(target) {
    window.open(target, "_blank");
    if (typeof props.drawer_close === "function") {
      props.drawer_close();
    }
  }

  const handleTabClick = (tabValue) => {
    console.log("Tab clicked Gsvb: " + tabValue);
    fetch(`${process.env.PUBLIC_URL}/${tabValue}.html`)
      .then((response) => response.text())
      .then((html) => {
        // Get the container element
        const container = document.getElementById("container1");

        // Insert the HTML into the container
        if (container) {
          container.innerHTML = html;
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };


  useEffect(() => {
    if (selectedOp.id !== undefined) {
      window.journalid = selectedOp.id;
      window.$name = selectedOp.name;
      document.title = t("GsVb") + selectedOp.name;
      navigate("/GsVb/" + selectedOp.id);
    }
  }, [selectedOp, navigate]);

  //**************************** DECLARE CONST *****************************************/

  //******************** HANDLECHANGE COMBOBOX *****************************************/
  //******************** HANDLECHANGE COMBOBOX *****************************************/

  if (loading) return <div>Loading...</div>;

  if (error) return `Error! ${error.message}`;
  //console.log("Result query",data.allOperation)
  if (data.allOperation.lenght === "0") {
    //  console.log("operation empty")
  }
  let operations = data.allOperation.filter(
    (j) => !filterState.includes(j.state)
  );

  //Set selected from url if not already set
  if (props.id !== undefined) {
    let urlParam = operations.filter((j) => j.id === props.id);
    if (selectedOp === "" && urlParam.length === 1) setSelectedOp(urlParam[0]);
  }

  if (data) {
    window.journalid = selectedOp.id;
    window.$name = selectedOp.name;
    return (
      <div style={{ fontFamily: "Arial", position: "relative" }}>
        <Stack
          style={{ padding: 8 }}
          className="content"
          spacing={2}
          sx={{ maxWidth: "500ch" }}
        >
          <Grid container>
            <FormControl fullWidth>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Grid item xs={5}>
                  <OperationsSelector
                    filterState={[]}
                    onSetOperation={(op) => setSelectedOp(op)}
                    value={!(selectedOp === null) ? selectedOp.id : ""}
                  />
                </Grid>
                <>
                  <Grid item xs={15} style={{ paddingLeft: 220 }}>
                    <ProcessTag handleTabClick={handleTabClick} />

                  </Grid>
                  <Grid item xs={1} style={{ paddingLeft: 10 }}>
                    <Button
                      onClick={() => {
                      navigateTo("/Promanager/" + selectedOp.id);
                      }}
                      aria-label="process-manager"
                      style={{ marginLeft: "auto" }} // Alignement à droite
                      variant="contained"
                      
                      disabled={!selectedOp}
                    >
                      Process Manager
                    </Button>
                  </Grid>
                </>
              </div>
            </FormControl>
          </Grid>
        </Stack>
        <GetEntriesTable
          operation={selectedOp.id}
          oprationname={selectedOp.name}
        />
      </div>
    );
  }
};
//<EditJournal />
//onClick={() => setConfigOpen(!configOpen)}
//  <AddEntry journalid={selected.value}/>
export default Getconsequences;

/*
 <GetEntriesTable operation={selectedOp.id} oprationname={selectedOp.name} /> */

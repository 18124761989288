
import { gql } from "@apollo/client";


export const MODIFY_CONTENT = gql`
mutation modMaximalJournalentry(
  $itemid: ID!
  $journalid: ID!
  $content: String!
){
      modifyJournalEntry(entry:{
        id: $itemid
        journal: $journalid
        content: $content
      })
      {
        id
        journal{
          id
        }
        createdAt
        content
        createdBy{name}
        followup
        followupAt
        followupBy{name}
        lastUpdate
        tags
      }
    }
    `; 
  export default MODIFY_CONTENT
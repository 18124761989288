import { ButtonGroup, Button, IconButton, Stack, Typography, TextField, Grid } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { useState, Fragment } from "react";
import { useMutation } from "@apollo/client";


import Selector from "components/ListSelector"
import { GET_ALLTASKLIST, MOD_TASKLIST } from "GraphQL/Tasklists";
import OperationsSelector from "components/OperationsSelector";
import AddTasklist from "./AddTasklist";


export default function TasklistsTab() {
    const { t } = useTranslation("admin");
    const [selectedT, setSelectedT] = useState(null);
    const [ModT, { loading, error }] = useMutation(MOD_TASKLIST);

    function changeHandler(event) {
        const { name, value } = event.target;
        setSelectedT(selectedOp => ({
            ...selectedOp, [name]: value
        }))
    }

    function isValid() {
        if (selectedT.name.length < 3) return false;
        return true;
    }

    function save() {
        if (isValid) {
            ModT({
                variables: {
                    t: (({ __typename, operation, ...t }) => {
                        //assure no modification if invalid/no operation
                        if(operation === null || operation.id === null){
                            t.operation = null;
                        }else if(operation.id === ""){
                            //set Operation to none
                            t.operation = 0;
                        }else{
                            t.operation = operation.id;
                        }
                        return t;
                    })(selectedT)
                },
                refetchQueries: [{ query: GET_ALLTASKLIST }, "AllTasklist"]
            })
        }
    }

    function getOpOfElement(element) {
        if (element.operation === null) return null;
        return element.operation.id;
    }
    function setOp(op) {
        setSelectedT(selectedT => ({
            ...selectedT, "operation": op
        }))
    }

    return (
        <Stack className="content" spacing={2} sx={{ maxWidth: "50ch" }}>
            <Typography variant="h2">{t("tasklistTabTitle")}</Typography>
            <p>{t("selectTasklist")}</p>
            <Grid container>
                <Grid item xs={11}><Selector filterState={[]} onSetList={(op) => setSelectedT(op)} value={!(selectedT === null) ? selectedT.id : ""} type="t" /></Grid>
                <Grid item xs={1}><AddTasklist onSet={(el) => setSelectedT(el)} /></Grid>
            </Grid>
            {(selectedT !== null) ?
                <Fragment>
                    <Typography variant="h5">{selectedT.name}</Typography>
                    <TextField disabled label={t("lblid")} value={selectedT.id} />
                    <TextField label={t("lblname")} value={selectedT.name} name="name" onChange={changeHandler} />
                    <Grid container>
                        <Grid item xs={11}><OperationsSelector value={getOpOfElement(selectedT)} name="operation" onSetOperation={setOp} /></Grid>
                        <Grid item xs={1}>
                            <IconButton className="btn" onClick={(e) => setOp({ id: "" })}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <ButtonGroup fullWidth name="state" onChange={changeHandler} value>
                        {["PLANNED", "ACTIVE", "CLOSED"].map(state =>
                            <Button
                                variant={(state === selectedT.state) ? "contained" : "outlined"}
                                onClick={changeHandler}
                                name="state"
                                value={state}
                                key={state}
                            >{state}
                            </Button>)
                        }
                    </ButtonGroup>
                    <Button disabled={!isValid()} variant="outlined" onClick={save}>{t("btnsave")}</Button>
                    {(loading) ? <Typography>Submitting...</Typography> : null}
                    {(error) ? <Typography>Submission error!<br />{error.message}</Typography> : null}
                </Fragment>
                : null}
        </Stack>
    );
}
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import OperationsSelector from "components/OperationsSelector";
import ProcessTab from "./ProcessTab";
import ProcessSVG from "./ProcessSVG";
import FileBrowserStack from "./FileBrowserStack";

export default function Processes(props) {
    const [selectedOp, setSelectedOp] = useState("");
    const [selectedProcess, setSelectedProcess] = useState("");
    const [folderviews, setFolderviews] = useState([]);

    function updateFolderviews(newFV) {
        if (JSON.stringify(newFV) !== JSON.stringify(folderviews))setFolderviews(newFV);
    }
    console.log("FolderViews: ", folderviews);
    return (
        <Stack direction={"column"} margin={"1EM"} spacing={"1EM"}>
            <Stack direction={"row"} spacing={"1EM"} >
                <Box minWidth={"30EM"}>
                    <OperationsSelector
                        filterState={[]}
                        onSetOperation={(op) => setSelectedOp(op)}
                        value={!(selectedOp === null) ? selectedOp.id : ""}
                    />
                </Box>
                {selectedOp &&
                    <ProcessTab op={selectedOp} onSetProcess={(process) => setSelectedProcess(process)} />
                }

            </Stack>
            <Stack direction={"row"} spacing={"1EM"}>
                {selectedProcess &&
                    <ProcessSVG op={selectedOp} process={selectedProcess} onSetFolderviews={updateFolderviews} />
                }
                {folderviews.length > 0 &&
                    <Box width={"100%"}>
                        <FileBrowserStack folders={folderviews} operation={selectedOp} clientPath={selectedProcess.clientPath}/>
                    </Box>
                }
            </Stack>
        </Stack>
    );
}

import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import CREATE_ENTRY from "GraphQL/AddMaxEntryFullConsequence";
import { FormGroup, Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { IconButton } from "@mui/material";
import {
  Modal,
  Button,
  TextInput,
  Textarea,
  useMantineTheme,
  Checkbox,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

const LOAD_ENTRIESC = gql`
  query LOAD_CONSEQUENCELIST($operation: ID!) {
    getConsequenceEntryByOperationId(operation: $operation) {
      operation {
        id
        name
        state
      }
      id
      createdAt
      content
      createdBy {
        name
      }
      source
      hrl
      er
      kr
      ek
      ef
      lastUpdate
    }
  }
`;


export default function AddEntry(journalid) {
  // const [open, setOpen] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [kr, setkr] = useState("");
  const [source, setSource] = useState("");
  const [status, setStatus] = useState("OPEN");
  const theme = useMantineTheme();
  const { t } = useTranslation("consequence");

  const [state, setState] = React.useState({
    hrlcheck: false,
    ercheck: false,
    efcheck: false,
    ekcheck: false,
  });

  const { hrlcheck, ercheck, efcheck, ekcheck } = state;

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const sendValue = (e) => {
    addjournalentry({
      variables: {
        name: name,
        content: content,
        operationid: journalid.journalid,
        createdBy: name,
        source: source,
        hrl: hrlcheck,
        er: ercheck,
        kr: kr,
        ek: ekcheck,
        ef: efcheck,
      },
    });
    setContent("");
    setkr("");
    setState(ercheck === false);
    setState(efcheck === false);
    setState(ekcheck === false);
    setState(hrlcheck === false);
    setName("");
    close();
    setSource("");
  };

  const handleCloseModal = () => {
    setContent("");
    setName("");
    setkr("");
    close();
    setStatus("");
    setSource("");
  };

  const [addjournalentry, { loading, error, data }] = useMutation(CREATE_ENTRY, {
    refetchQueries: [{ query: LOAD_ENTRIESC, variables: { operation: window.journalid } }],
  });

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const isButtonDisabled = name.length < 1 || content.length < 1;

  const enabled = journalid.journalid !== undefined;

  return (
    <div>
      <Tooltip title={t("addentry.btnaddentry")}>
        <span>
          <IconButton
            className="btn"
            onClick={() => open()}
            disabled={!enabled}
          >
            <NoteAddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Modal
        opened={opened}
        onClose={close}
        // onClose={handleCloseModal}
        title={t("addentry.modal.title")}
        size="xl"
        centered
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          autoFocus
          margin="10px"
          label={t("addentry.modal.creator")}
          placeholder={t("addentry.modal.creator")}
          type="name"
          value={name}
          selectedvalue="name"
          onChange={(e) => setName(e.target.value)}
          data-autofocus
          withAsterisk
          mx={"xl"}
          m={"sm"}
        />
        <Textarea
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          id="outlined-multiline-static"
          margin="5px"
          label={t("addentry.modal.content")}
          placeholder={t("addentry.modal.content")}
          withAsterisk
          minRows={4}
          maxRows={4}
          radius="md"
          mx={"xl"}
          m={"sm"}
        />
        <TextInput
          autoFocus
          margin="10px"
          label={t("addentry.modal.source")}
          placeholder={t("addentry.modal.source")}
          type="name"
          value={source}
          onChange={(e) => setSource(e.target.value)}
          mx={"xl"}
          m={"sm"}
        />

        <FormGroup>
          <Checkbox
            checked={hrlcheck}
            onChange={handleChange}
            name="hrlcheck"
            label={t("addentry.modal.hrl")}
            color="primary"
            mx={"xl"}
            m={"sm"}
          />
          <Checkbox
            checked={ercheck}
            onChange={handleChange}
            name="ercheck"
            label={t("addentry.modal.er")}
            color="primary"
            mx={"xl"}
            m={"sm"}
          />
          <TextInput
            autoFocus
            margin="10px"
            label={t("addentry.modal.kr")}
            placeholder={t("addentry.modal.kr")}
            type="KR"
            value={kr}
            onChange={(e) => setkr(e.target.value)}
            mx={"xl"}
            m={"sm"}
          />
          <Checkbox
            checked={ekcheck}
            onChange={handleChange}
            name="ekcheck"
            label={t("addentry.modal.ek")}
            color="primary"
            mx={"xl"}
            m={"sm"}
          />
          <Checkbox
            checked={efcheck}
            onChange={handleChange}
            name="efcheck"
            label={t("addentry.modal.ef")}
            color="primary"
            mx={"xl"}
            m={"sm"}
          />
        </FormGroup>
        <Button
          onClick={(e) => {
            handleCloseModal(e);
          }}
          mx={"xl"}
        >
          {t("addentry.modal.btncancel")}
        </Button>
        <Button
          type="submit"
          onClick={sendValue}
          mx={"xl"}
          disabled={isButtonDisabled}
        >
          {t("addentry.modal.btnsave")}
        </Button>
      </Modal>
    </div>
  );
}

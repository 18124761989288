import { useMutation } from "@apollo/client";
import { IconButton, Tooltip } from "@mui/material";
import {
  Modal,
  Button,
  TextInput,
  useMantineTheme,
  Group,
} from "@mantine/core";
import React, { useState } from "react";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useTranslation } from "react-i18next";
import { ADD_OPERATION, GET_ALLOPERATION } from "GraphQL/Operations";

export default function AddOperation(props) {
  let setOperationCallback =
    props.onSetOperation ||
    function (newop) {
      console.log(newop);
    };
  const [CreateOp, { loading, error }] = useMutation(ADD_OPERATION);
  const [open, setOpen] = useState(false);
  const [opName, setOpName] = useState("");
  const theme = useMantineTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { t } = useTranslation("admin");

  function close() {
    setOpen(false);
    setOpName("");
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      save();
    }
  };

  function save() {
    if (isValidOpName) {
      CreateOp({
        variables: {
          name: opName,
        },
        refetchQueries: [{ query: GET_ALLOPERATION }],
        onCompleted: (data) => {
          if (!error) {
            close();
            setOperationCallback(data.newOperation);
          }
        },
      });
    }
  }

  function isValidOpName() {
    if (opName.length < 3) return false;
    return true;
  }

  return (
    <React.Fragment>
      <Tooltip title={t("tooltip.addop")}>
        <IconButton className="btn" onClick={handleClickOpen}>
          <NoteAddIcon />
        </IconButton>
      </Tooltip>

      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        onKeyDown={handleKeyDown}
        title={t("dialog.newop.title")}
        size="xl"
        centered
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          label={t("dialog.newop.lblopname")}
          placeholder={t("dialog.newop.lblopname")}
          type="name"
          onChange={(e) => setOpName(e.target.value)}
          data-autofocus
          withAsterisk
          mx={"sm"}
          m={"sm"}
        />
        {loading ? <Modal>Submitting...</Modal> : null}
        {error ? (
          <Modal>
            Submission error!
            <br />
            {error.message}
          </Modal>
        ) : null}
        <Group mt="xl">
          <Button mx={"xl"} onClick={close}>
            {t("dialog.btncancel")}
          </Button>
          <Button mx={"xl"} onClick={save} disabled={!isValidOpName()}>
            {t("dialog.btnsave")}
          </Button>
        </Group>
      </Modal>
    </React.Fragment>
  );
}

import { ButtonGroup, Button, IconButton, Stack, Typography, TextField, Grid } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { useState, Fragment } from "react";
import { useMutation } from "@apollo/client";


import Selector from "components/ListSelector"
import { GET_ALLJOURNAL, MOD_JOURNAL } from "GraphQL/Journals";
import OperationsSelector from "components/OperationsSelector";
import AddJournal from "./AddJournal";


export default function JournalsTab() {
    const { t } = useTranslation("admin");
    const [selectedJ, setSelectedJ] = useState(null);
    const [ModJ, { loading, error }] = useMutation(MOD_JOURNAL);

    function changeHandler(event) {
        const { name, value } = event.target;
        setSelectedJ(selectedOp => ({
            ...selectedOp, [name]: value
        }))
    }

    function isValid() {
        if (selectedJ.name.length < 3) return false;
        return true;
    }

    function save() {
        if (isValid) {
            ModJ({
                variables: {
                    j: (({ __typename, operation, ...j }) => {
                        //assure no modification if invalid/no operation
                        if(operation === null || operation.id === null){
                            j.operation = null;
                        }else if(operation.id === ""){
                            //set Operation to none
                            j.operation = 0;
                        }else{
                            j.operation = operation.id;
                        }
                        return j;
                    })(selectedJ)
                },
                refetchQueries: [{ query: GET_ALLJOURNAL }, "AllJournal"]
            })
        }
    }

    function getOpOfElement(element) {
        if (element.operation === null) return null;
        return element.operation.id;
    }
    function setOp(op) {
        setSelectedJ(selectedJ => ({
            ...selectedJ, "operation": op
        }))
    }

    return (
        <Stack className="content" spacing={2} sx={{ maxWidth: "50ch" }}>
            <Typography variant="h2">{t("journalsTabTitle")}</Typography>
            <p>{t("selectJournal")}</p>
            <Grid container>
                <Grid item xs={11}><Selector filterState={[]} onSetList={(op) => setSelectedJ(op)} value={!(selectedJ === null) ? selectedJ.id : ""} /></Grid>
                <Grid item xs={1}><AddJournal onSet={(el) => setSelectedJ(el)} /></Grid>
            </Grid>
            {(selectedJ !== null) ?
                <Fragment>
                    <Typography variant="h5">{selectedJ.name}</Typography>
                    <TextField disabled label={t("lblid")} value={selectedJ.id} />
                    <TextField label={t("lblname")} value={selectedJ.name} name="name" onChange={changeHandler} />
                    <Grid container>
                        <Grid item xs={11}><OperationsSelector value={getOpOfElement(selectedJ)} name="operation" onSetOperation={setOp} /></Grid>
                        <Grid item xs={1}>
                            <IconButton className="btn" onClick={(e) => setOp({ id: "" })}>
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <ButtonGroup fullWidth name="state" onChange={changeHandler} value>
                        {["PLANNED", "ACTIVE", "CLOSED"].map(state =>
                            <Button
                                variant={(state === selectedJ.state) ? "contained" : "outlined"}
                                onClick={changeHandler}
                                name="state"
                                value={state}
                                key={state}
                            >{state}
                            </Button>)
                        }
                    </ButtonGroup>
                    <Button disabled={!isValid()} variant="outlined" onClick={save}>{t("btnsave")}</Button>
                    {(loading) ? <Typography>Submitting...</Typography> : null}
                    {(error) ? <Typography>Submission error!<br />{error.message}</Typography> : null}
                </Fragment>
                : null}
        </Stack>
    );
}
import { Box, Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import OperationsTab from 'components/admin/OperationsTab';
import JournalsTab from 'components/admin/JournalsTab';
import TasklistsTab from 'components/admin/TasklistsTab';


export default function AdminPage() {
    const [tab, setTab] = React.useState(0);
    const { t } = useTranslation("admin");

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleChange} centered >
                    <Tab label={t("operation")} value={0} />
                    <Tab label={t("journal")} value={1} />
                    <Tab label={t("tasklist")} value={2} />
                </Tabs>
            </Box>
            {tab === 0 ? <OperationsTab/>: null }
            {tab === 1 ? <JournalsTab/>: null }
            {tab === 2 ? <TasklistsTab/>: null }
        </React.Suspense>
    );
}
import { Stack } from '@mui/material';
import * as React from 'react';
import FileBrowser from './FileBrowser';
import { uniqueId } from 'lodash';

export default function FileBrowserStack(props) {
    let folders = props.folders
    let operation = props.operation
    let clientPath = props.clientPath
    return (
        <Stack direction={"column"} spacing={"1EM"} maxWidth={"true"}>
            {folders.map((folder) => (
                <FileBrowser path={folder.path} name={folder.name} operation={operation} clientPath={clientPath} key={uniqueId()}/> 
            ))}
        </Stack>
    );
}
import { gql } from "@apollo/client";

export const GET_FOLDER_BY_OP_ID = gql`
query GetFolderByOperationID(
  $operation : ID!,
  $path: String!
) {
    getFolderByOperationID(operation: $operation , path: $path) {
      name
      lastUpdate
      path
    }
  }
`;
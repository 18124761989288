import React, { useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";

import { MetroSpinner } from "react-spinners-kit";
//Import Material React Table Translations
//import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_FR } from "material-react-table/locales/fr";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { darken, Typography } from "@mui/material";

//****************** IMPORT GQL ****************************/
import MODIFY_ENTRY from "GraphQL/ModifyConsequenceEntry";
import MODIFY_CONTENT from "GraphQL/ModifyConsequencecontent";

//****************** IMPORT GQL ****************************/

//************************ ICON ****************************/
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
//import RestoreIcon from "@mui/icons-material/Restore";
//************************ ICON ****************************/
import logo from "pictures/bundlogo.png";
import { useQuery, gql, useMutation } from "@apollo/client";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Button,
  Box,
  Checkbox,
  Textarea,
  Modal,
  useMantineTheme,
} from "@mantine/core";
import { debounce } from "lodash";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

const LOAD_ENTRIESC = gql`
  query LOAD_CONSEQUENCELIST($operation: ID!) {
    getConsequenceEntryByOperationId(operation: $operation) {
      operation {
        id
        name
        state
      }
      id
      createdAt
      content
      createdBy {
        name
      }
      source
      hrl
      er
      kr
      ek
      ef
      lastUpdate
    }
  }
`;
//tags

const MODIFY_CHECK_ENTRY = gql`
  mutation modifyCheckEntry(
    $id: ID!
    $operation: ID!
    $hrl: Boolean
    $er: Boolean
    $ek: Boolean
    $ef: Boolean
  ) {
    modifyConsequenceEntry(
      entry: {
        operation: $operation
        id: $id
        hrl: $hrl
        er: $er
        ek: $ek
        ef: $ef
      }
    ) {
      operation {
        id
      }
      id
      hrl
      er
      kr
      ek
      ef
    }
  }
`;

const GetEntriesTable = (operation, operationName, refresh) => {
  //**************************** DECLARE CONST *****************************************/
  const [modConse, { errorm, dataj }] = useMutation(MODIFY_ENTRY);
  const [modCheck] = useMutation(MODIFY_CHECK_ENTRY);
  const [modContent] = useMutation(MODIFY_CONTENT);
  const [opened, { open, close }] = useDisclosure(false);
  const [idcontent, setidcontent] = useState(null);
  const theme = useMantineTheme();
  const [selectedContent, setSelectedContent] = useState("");
  //const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation("consequence");
  const ExcelJS = require("exceljs");
  const [state] = React.useState({
    hrlcheck: "",
    ercheck: "",
    efcheck: "",
    ekcheck: "",
  });

  let MRT_language = "";

  //console.log("Language : ", i18n.language);

  if (i18n.language === "fr") {
    MRT_language = MRT_Localization_FR;
  }
  if (i18n.language === "de") {
    MRT_language = MRT_Localization_DE;
  }
  if (i18n.language === "en") {
    MRT_language = MRT_Localization_EN;
  }

  const { loading, error, data } = useQuery(LOAD_ENTRIESC, {
    variables: { operation: operation.operation },
     pollInterval : operation.refresh * 1000,
  });

  //**************************** DECLARE CONST *****************************************/

  //******************************** Export to PDF  *****************************************************************/
  const downloadPdf = (data) => {
    console.log(data);
    const doc = new jsPDF("l", "pt", "a4");
    var img = new Image();
    img.src = logo;
    //ADD TITLE
    // doc.addFont('Arial', 'Arial', 'normal');
    //doc.setFont("Arial");
    doc.setFontSize(14);
    doc.text(
      window.$name,
      doc.internal.pageSize.getWidth() / 2,
      35,
      null,
      null,
      "center"
    );
    //ADD BUNDLOGO
    doc.addImage(img, "png", 2, 2, 200, 50);
    //ADD BUNDTEXT
    doc.setFontSize(10);
    doc.text(t("getallconsequences.headerdepartemenrt1"), 600, 15);
    doc.text(t("getallconsequences.headerdepartemenrt2"), 600, 27);
    doc.text(t("getallconsequences.headerarmee"), 600, 39);

    // Modify each inner array to change boolean values to "yes" and "no" for the "HRL" field at index 5
    const modifiedData = data.map((rowArray) => {
      const modifiedRowArray = [...rowArray];
      const hrlValue = modifiedRowArray[5]; // Get the value of "HRL" at index 5
      const erValue = modifiedRowArray[6]; // Get the value of "HRL" at index 6
      const ekValue = modifiedRowArray[8]; // Get the value of "HRL" at index 8
      const efValue = modifiedRowArray[9]; // Get the value of "HRL" at index 9
      // Change the "HRL" value to "yes" or "no" based on its boolean value
      modifiedRowArray[5] = hrlValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");
      modifiedRowArray[6] = erValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");
      modifiedRowArray[8] = ekValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");
      modifiedRowArray[9] = efValue
        ? t("tablelibrary.yes")
        : t("tablelibrary.no");

      return modifiedRowArray;
    });

    //ADD DATA TABLE
    doc.autoTable({
      theme: "striped",
      showHead: "everyPage",
      margin: { top: 60, right: 14, bottom: 50, left: 14 },
      cellWidth: "wrap",
      // columnWidth: "wrap",
      columnStyles: {
        0: {
          cellWidth: 30,
        },
        1: {
          cellWidth: 70,
        },
        2: {
          cellWidth: 70,
        },
        3: {
          cellWidth: 70,
        },
        4: {
          cellWidth: 70,
        },
        5: {
          cellWidth: 30,
        },
        6: {
          cellWidth: 70,
        },
        7: {
          cellWidth: 150,
        },
        8: {
          cellWidth: 70,
        },
        9: {
          cellWidth: 70,
        },
      },

      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: modifiedData,
    });
    console.log(modifiedData);
    //FOOTER
    var i = "";
    var pageCount = doc.internal.getNumberOfPages(); //Total Page Number
    for (i = 0; i < pageCount; i++) {
      doc.setPage(i);
      let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
      doc.text(t("page") + pageCurrent + "/" + pageCount, 20, 580);
    }
    doc.save(window.$name + ".pdf");
  };
  //******************************** Export to PDF  *****************************************************************/

  const handleSaveCell = (cell, value) => {
    let contentdata = "";
    let createdBynamedata = "";
    let sourcedata = "";
    let hrldata = "";
    let ekdata = "";
    let efdata = "";
    let erdata = "";
    let krdata = "";

    let iddata = data.getConsequenceEntryByOperationId[cell.row.index].id;
    //console.log("ID", data.getConsequenceEntryByOperationId[cell.row.index].id);
    //console.log("Cell ", cell.column.id);

    if (cell.column.id === "content") {
      contentdata = value;
    } else {
      contentdata =
        data.getConsequenceEntryByOperationId[cell.row.index].content;
    }

    if (cell.column.id === "createdByname") {
      createdBynamedata = value;
    } else {
      createdBynamedata =
        data.getConsequenceEntryByOperationId[cell.row.index].createdBy.name;
      if (createdBynamedata === null) {
        createdBynamedata = "";
      }
    }
    if (cell.column.id === "source") {
      sourcedata = value;
    } else {
      sourcedata = data.getConsequenceEntryByOperationId[cell.row.index].source;
      if (sourcedata === null) {
        sourcedata = "";
      }
    }

    if (cell.column.id === "hrl") {
      if (data.getConsequenceEntryByOperationId[cell.row.index].hrl === false) {
        state.hrlcheck = true;
      } else {
        state.hrlcheck = false;
      }
      hrldata = state.hrlcheck;
    }

    if (cell.column.id === "er") {
      if (data.getConsequenceEntryByOperationId[cell.row.index].er === false) {
        state.ercheck = true;
      } else {
        state.ercheck = false;
      }
      erdata = state.ercheck;
    }

    if (cell.column.id === "ek") {
      if (data.getConsequenceEntryByOperationId[cell.row.index].ek === false) {
        state.ekcheck = true;
      } else state.ekcheck = false;

      ekdata = state.ekcheck;
    }

    if (cell.column.id === "kr") {
      krdata = value;
    } else {
      krdata = data.getConsequenceEntryByOperationId[cell.row.index].kr;
      if (krdata === null) {
        krdata = "";
      }
    }

    if (cell.column.id === "ef") {
      if (data.getConsequenceEntryByOperationId[cell.row.index].ef === false) {
        state.efcheck = true;
      } else state.efcheck = false;
    }

    if (erdata === "")
      erdata = data.getConsequenceEntryByOperationId[cell.row.index].er;
    if (efdata === "")
      efdata = data.getConsequenceEntryByOperationId[cell.row.index].ef;
    if (ekdata === "")
      ekdata = data.getConsequenceEntryByOperationId[cell.row.index].ek;
    if (hrldata === "")
      hrldata = data.getConsequenceEntryByOperationId[cell.row.index].hrl;

    //console.log(iddata, "operation", operation.operation,contentdata,createdBynamedata,sourcedata,hrldata,krdata,efdata,ekdata,erdata)

    let vars = {
      id: iddata,
      operation: operation.operation,
      content: contentdata,
      createdBy: createdBynamedata,
      sourcedata: sourcedata,
      hrl: hrldata,
      kr: krdata,
      ef: efdata,
      ek: ekdata,
      er: erdata,
    };

    modConse({ variables: vars });
  };

  //**************************** HANDLE MODAL *****************************************/
  const handleContentClick = (row) => {
    // Set the value of content and itemid
    setSelectedContent(row.original.content);
    setidcontent(row.original.id);
    open();

    // setIsModalOpen(true);
  };

  // Debounce the input handling function
  const debouncedHandleChange = debounce((value) => {
    setSelectedContent(value);
  }, 700); // Adjust the debounce delay as needed

  const handleSaveChanges = () => {
    modContent({
      variables: {
        id: idcontent,
        operation: window.journalid,
        content: selectedContent,
      },
    });
    close();
  };
  //**************************** HANDLE MODAL *****************************************/

  if (dataj) {
  }
  // ****************************** DBL CLICK EDIT / SAVE ***********************************************************/

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "id",
        size: 2,
        enableEditing: false,
        enableColumnActions: false,
      },
      {
        accessorKey: "creationDate", //simple recommended way to define a column
        header: t("tablelibrary.creationdate"),
        enableEditing: false,
        size: 20,
        enableColumnFilter: false,
      },
      {
        accessorKey: "createdByname",
        header: t("tablelibrary.creator"),
        size: 10,
      },
      {
        accessorKey: "content", //simple recommended way to define a column
        header: t("tablelibrary.content"),
        style: { whiteSpace: "normal", wordWrap: "break-word" }, //Add this line to the column definition
        size: 80,
        enableEditing: false,
        Cell: ({ cell, row, column }) => (
          <span
            style={{ whiteSpace: "pre-line" }}
            onDoubleClick={() => handleContentClick(row)}
          >
            <div onDoubleClick={() => handleContentClick(row)}>
              {cell.renderValue()}
            </div>
          </span>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          variant: "outlined",
          multiline: true,
        }),
      },
      {
        accessorKey: "source", //simple recommended way to define a column
        header: t("tablelibrary.source"),
        size: 50,
      },
      {
        accessorKey: "hrl", //simple recommended way to define a column
        header: t("tablelibrary.hrl"),
        enableEditing: false,
        size: 10,
        type: Checkbox,
        Cell: ({ cell }) => (
          <>
            {cell.getValue("hrl") === true ? (
              <Checkbox checked={true} color="primary" size="md" readOnly />
            ) : (
              <Checkbox checked={false} color="secondary" size="md" readOnly />
            )}
          </>
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },
      {
        accessorKey: "er", //simple recommended way to define a column
        header: t("tablelibrary.er"),
        enableEditing: false,
        size: 10,
        type: Boolean,
        Cell: ({ cell }) => (
          <>
            {cell.getValue("er") === true ? (
              <Checkbox checked={true} color="primary" size="md" readOnly />
            ) : (
              <Checkbox checked={false} color="secondary" size="md" readOnly />
            )}
          </>
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },
      {
        accessorKey: "kr", //simple recommended way to define a column
        header: t("tablelibrary.kr"),
        size: 10,
      },
      {
        accessorKey: "ek", //simple recommended way to define a column
        header: t("tablelibrary.ek"),
        enableEditing: false,
        size: 10,
        type: Boolean,
        Cell: ({ cell }) => (
          <>
            {cell.getValue("ek") === true ? (
              <Checkbox checked={true} color="primary" size="md" readOnly />
            ) : (
              <Checkbox checked={false} color="secondary" size="md" readOnly />
            )}
          </>
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },

      {
        accessorKey: "ef", //simple recommended way to define a column
        header: t("tablelibrary.ef"),
        enableEditing: false,
        size: 5,
        // type: Chip,
        accessorFn: (originalRow) => (originalRow.ef ? true : false),
        Cell: ({ cell }) => (
          <>
            {cell.getValue("ef") === true ? (
              <Checkbox checked={true} color="primary" size="md" readOnly />
            ) : (
              <Checkbox checked={false} color="secondary" size="md" readOnly />
            )}
          </>
        ),
        filterVariant: "checkbox",
        muiTableHeadCellFilterCheckboxProps: () => {
          return { title: t("tablelibrary.check"), color: "secondary" };
        },
      },
      {
        accessorKey: "lastUpdate", //simple recommended way to define a column
        header: t("tablelibrary.lastupdate"),
        enableEditing: false,
        size: 20,
        enableColumnFilter: false,
      },
    ],
    []
  );
  // ******************************************** Define Colums for Table *************************************************************

  //************************ NEW EXPORT CSV **************************************** */
  const exportExcelFile = (dataforexecel) => {
    const workbook = new ExcelJS.Workbook();

    var sheet = workbook.addWorksheet(window.$name, {
      pageSetup: {
        horizontalCentered: true,
        paperSize: 9,
        orientation: "landscape",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    sheet.getRow(1).height = 30;
    sheet.pageSetup.printTitlesColumn = "1:1";
    sheet.autoFilter = {
      from: "A1",
      to: "K1",
    };

    //Define Border of Row 1 Header
    sheet.getRow(1).eachCell({ includeEmpty: false }, function (cell) {
      sheet.getCell(cell.address).border = {
        top: { style: "thick", color: { argb: "FFFF0000" } },
        left: { style: "thick", color: { argb: "00000000" } },
        bottom: { style: "thick", color: { argb: "000000" } },
        right: { style: "thick", color: { argb: "FF00FF00" } },
      };
    });

    const columnsStyle = { font: { name: "Arial Black", size: 10 } };

    //Define Headers Colums and set Backgroundcolor
    let Columsexcel = [
      "A1",
      "B1",
      "C1",
      "D1",
      "E1",
      "F1",
      "G1",
      "H1",
      "I1",
      "J1",
      "K1",
    ];
    Columsexcel.forEach((cellAddress) => {
      const cell = sheet.getCell(cellAddress);
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "00000000" },
        bgColor: { argb: "96C8FB" },
      };
    });

    //Define fonc option
    sheet.getRow(1).font = {
      name: "Arial",
      family: 4,
      size: 13,
      bold: true,
      font: { color: { argb: "#FFFFFF" } },
      color: { argb: "FFFFFF" },
    };

    sheet.columns = [
      {
        header: t("tablelibrary.id"),
        key: "id",
        width: 4,
      },
      {
        header: t("tablelibrary.creationdate"),
        key: "creationDate",
        width: 15,
      },
      {
        header: t("tablelibrary.creator"),
        key: "createdByname",
        width: 18,
      },
      {
        header: t("tablelibrary.content"),
        key: "content",
        width: 25,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.source"),
        key: "source",
        width: 20,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.hrl"),
        key: "hrl",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.er"),
        key: "er",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.kr"),
        key: "kr",
        width: 10,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.ek"),
        key: "ek",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.ef"),
        key: "ef",
        width: 7,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
      {
        header: t("tablelibrary.lastupdate"),
        key: "lastUpdate",
        width: 15,
        style: {
          name: "PO#",
          style: columnsStyle,
          alignment: { wrapText: true },
        },
      },
    ];

    // Modify the dataforexecel array to change boolean values to "yes" and "no"
    const modifiedData = dataforexecel.map((element) => ({
      ...element,
      hrl: element.hrl ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
      er: element.er ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
      ek: element.ek ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
      ef: element.ef ? t("tablelibrary.yes") : t("tablelibrary.no"), // Change boolean to "yes" or "no"
    }));

    // ... (existing code)

    modifiedData.forEach((element) => {
      sheet.addRow({
        id: element.id,
        content: element.content,
        creationDate: element.creationDate,
        createdByname: element.createdByname,
        source: element.source,
        hrl: element.hrl,
        er: element.er,
        kr: element.kr,
        ek: element.ek,
        ef: element.ef,
        lastUpdate: element.lastUpdate,
      });
    });

    //Generate the Xlsx file
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = window.$name + ".xlsx";
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };
  //************************ NEW EXPORT CSV **************************************** */
  const handlecheckchange = (cell_id, journal_id, value_cell, id_content,Refresh) => {
    let valuehrl = null;
    let valueef = null;
    let valueek = null;
    let valueer = null;

    //console.log("HandleCheck", cell_id, journal_id, value_cell, id_content)
    if (value_cell === true) {
      value_cell = false;
    } else {
      value_cell = true;
    }
    const totoggle = cell_id.substring(2);
    // console.log("Variable ", totoggle)
    if (totoggle === "hrl") valuehrl = value_cell;
    if (totoggle === "ef") valueef = value_cell;
    if (totoggle === "er") valueer = value_cell;
    if (totoggle === "ek") valueek = value_cell;

    let vars = {
      id: id_content,
      operation: operation.operation,
      hrl: valuehrl,
      ef: valueef,
      ek: valueek,
      er: valueer,
    };

    modCheck({ variables: vars });
    //console.log("Variable to modify check: ",vars)
  };

  if (errorm) return `Error! ${errorm.message}`;
  if (loading) {
    return (
      <div className="spinner">
        <MetroSpinner size={40} color="black" loading={loading} />
      </div>
    );
  }

  if (error) return console.log(`Something get wrong"  ${error.message}`);

  if (data) {
    //console.log("Result Query array", data);
    let queryresult = data.getConsequenceEntryByOperationId.map((element) => {
      let e = structuredClone(element);
      e.createdByname = element.createdBy.name;
      if (
        element.createdAt !== null &&
        element.createdAt !== undefined &&
        element.createdAt !== ""
      ) {
        e.creationDate =
          element.createdAt.slice(8, 10) +
          "." +
          element.createdAt.slice(5, 7) +
          "." +
          element.createdAt.slice(2, 4) +
          " " +
          element.createdAt.split("T")[1].slice(0, 5);
      }

      if (
        element.lastUpdate !== null &&
        element.lastUpdate !== undefined &&
        element.lastUpdate !== ""
      ) {
        e.lastUpdate =
          element.lastUpdate.slice(8, 10) +
          "." +
          element.lastUpdate.slice(5, 7) +
          "." +
          element.lastUpdate.slice(2, 4) +
          " " +
          element.lastUpdate.split("T")[1].slice(0, 5);
      }

      return e;
    });
    // console.log("queryresult", queryresult);

    return (
      <>
        <MaterialReactTable
          columns={columns}
          data={queryresult}
          editingMode="cell"
          enableEditing
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: darken(theme.palette.background.default, 0.1),
              },
            }),
          }}
          //ADD Border to cell
          /*        muiTableBodyCellProps={{
            sx: {
              borderRight: '2px solid #e0e0e0', //add a border between columns
            },
          }} */
          //DT201 ADD edit on doubleclick

          muiTableBodyCellEditTextFieldProps={({ cell, row }) => ({
            onBlur: (event) => {
              handleSaveCell(cell, event.target.value, row);
            },
          })}
          //DT201 ADD edit on doubleclick
          enableColumnOrdering
          enableColumnResizing
          // enableClickToCopy
          enableColumnFilterChangeMode
          enableFullScreenToggle={false}
          maxsize="100%"
          options={{}}
          muiTableHeadCellProps={{
            sx: {
              "& .Mui-TableHeadCell-Content": {
                justifyContent: "space-between",
              },
            },
          }}
          initialState={{
            density: "compact",
            pagination: { pageIndex: 0, pageSize: 30 },
          }}
          muiTableBodyCellProps={({ cell, row }) => ({
            onDoubleClick: (event) => {
              //console.info(event, "Cell id",cell.id, " journalid", window.journalid, "Value",cell.getValue(),"itemid ",cell.row.original.id);
              handlecheckchange(
                cell.id,
                window.journalid,
                cell.getValue(),
                cell.row.original.id
              );
            },
          })}
          renderBottomToolbarCustomActions={() => (
            <Typography
              sx={{ fontStyle: "italic", p: "0 1rem" }}
              variant="body2"
            >
              {t("tablelibrary.dblclick2edit")}
            </Typography>
          )}
          //enableRowNumbers
          //enableRowSelection
          //onEditRowSubmit={setRowSelection}
          localization={MRT_language}
          // initialState={{ columnVisibility: { id: false } }} //hide ID column by default
          positionToolbarAlertBanner="bottom"
          renderTopToolbarCustomActions={({ row, table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                onClick={(e) => {
                  downloadPdf(
                    queryresult.map((el) => [
                      el.id,
                      el.createdBy.name,
                      el.creationDate,
                      el.content,
                      el.source,
                      el.hrl,
                      el.er,
                      el.kr,
                      el.ek,
                      el.ef,
                      el.lastUpdate,
                    ])
                  );
                }}
                leftIcon={<FileDownloadIcon />}
                compact
                variant="light"
              >
                {t("tablelibrary.btnexport2pdf")}
              </Button>
              <Button
                //color="secondary"
                onClick={(e) => {
                  exportExcelFile(queryresult);
                }}
                //onClick={(e)  => exportToCSV(queryresult, "Combat Journal " + window.$name)}
                leftIcon={<FileDownloadIcon />}
                compact
                variant="light"
              >
                {t("tablelibrary.btnexport2xlsx")}
              </Button>
              <Button
                onClick={() => table.resetColumnFilters()}
                leftIcon={<FilterAltOffIcon />}
                compact
                variant="light"
              >
                {t("tablelibrary.tclearfilter")}
              </Button>
            </Box>
          )}
        />
        <Modal
          centered
          opened={opened}
          onClose={() => close()}
          title={t("tablelibrary.modal.editcontent")}
          size="xl"
          mx={"xl"}
          m={"xl"}
          overlayProps={{
            color:
              theme.colorScheme === "dark"
                ? theme.colors.dark[9]
                : theme.colors.gray[2],
            opacity: 0.55,
            blur: 3,
          }}
        >
          <Textarea
            type="text"
            defaultValue={selectedContent}
            id="outlined-multiline-static"
            onChange={(e) => debouncedHandleChange(e.target.value)}
            margin="5px"
            label={t("tablelibrary.modal.content")}
            withAsterisk
            minRows={4}
            maxRows={7}
            radius="md"
            mx="xl"
            rows={10}
            cols={50}
            m="xl"
            autosize
          />
          <Button
            size="sm"
            m={"sm"}
            compact
            mx={"xl"}
            variant="outline"
            onClick={handleSaveChanges}
          >
            {t("tablelibrary.modal.btnsave")}
          </Button>
        </Modal>
      </>
    );
  }
};

export default GetEntriesTable;

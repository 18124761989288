import * as React from 'react';
import GetEntriesTable from "../components/journal/GetJournals"

export default function JournalPagename() {
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            console.log("windows.$name","Select journal");
            <GetEntriesTable journal={window.journalid} journalname={window.$name} />
        </React.Suspense>
    );
}
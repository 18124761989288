import { gql } from "@apollo/client";


export const MODIFY_CCONTENT = gql`
mutation modifyConsequenceContent(
  $operation : ID!
  $id: ID!
  $content: String
){
  modifyConsequenceEntry(entry:{
        operation: $operation
        id: $id
        content: $content
      })
      {
      operation{
        id
      }
      id
      createdAt
      content
      createdBy{name}
      hrl
      er
      kr
      ek
      ef
      source
      lastUpdate
  }
    }
      `;
export default MODIFY_CCONTENT;


/* hrl: $hrl
er: $er
ek: $ek
ef: $ef */
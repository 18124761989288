import { gql } from "@apollo/client";

export const LOAD_OPERATIONS = gql`
  query LOADALLOPERATION {
    allOperation
    {
      name
      state
        id
        name
    }
  }
`;

export default LOAD_OPERATIONS;
 
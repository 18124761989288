import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import CREATE_ENTRY from "GraphQL/AddMaxEntryFullVariables";
import { Tooltip, Grid } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { IconButton } from "@mui/material";
import { LOAD_ENTRIES } from "GraphQL/Journals";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Button,
  TextInput,
  Textarea,
  useMantineTheme,
  Group,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export default function AddEntry(tasklistid) {
  const [opened, { open, close }] = useDisclosure(false);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const theme = useMantineTheme();
  const { t } = useTranslation("journal");

  const sendValue = (val) => {
    if (name !== "" && value !== "") {
      addjournalentry({
        variables: {
          name: name,
          content: val,
          journalid: window.journalid,
        },
        refetchQueries: [
          {
            query: LOAD_ENTRIES,
            variables: { journal: window.journalid },
          },
        ],
      });
      setName("");
      setValue("");
      close();
    } else {
      open();
    }
  };

  const handleCloseModal = () => {
    setName("");
    setValue("");
    close();
  };

  const isButtonDisabled = name.length < 1 || value.length < 1;

  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const [addjournalentry, { loading, error }] = useMutation(CREATE_ENTRY);

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const enabled = window.journalid !== undefined;

  return (
    <div>
      <Tooltip title={t("addentry.btnaddentry")}>
        <span>
          <IconButton
            className="btn"
            onClick={() => open()}
            disabled={!enabled}
            type="button"
          >
            <NoteAddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Modal
        opened={opened}
        onClose={close}
        title={t("addentry.modal.title")}
        size="xl"
        centered
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          autoFocus
          margin="10px"
          label={t("addentry.modal.creatorname")}
          placeholder={t("addentry.modal.creatorname")}
          type="name"
          value={name}
          selectedvalue="name"
          onChange={(e) => setName(e.target.value)}
          data-autofocus
          withAsterisk
          mx={"xl"}
          m={"xl"}
        />
        <Textarea
          type="text"
          value={value}
          onChange={handleInputChange}
          id="outlined-multiline-static"
          margin="5px"
          label={t("addentry.modal.content")}
          withAsterisk
          minRows={6}
          maxRows={7}
          radius="md"
          mx={"xl"}
        />
        <Grid container justify="center">
          <Grid item>
            <Button
              onClick={() => setValue(t("addentry.modal.aeiou"))}
              variant="light"
              size="80%"
              compact
              mx={"xl"}
            >
              {t("addentry.modal.btnaeiou")}
            </Button>
          </Grid>
        </Grid>
        <Group mt="xl">
          <Button
            variant="outline"
            onClick={handleCloseModal}
            size="sm"
            mx={"xl"}
          >
            {t("addentry.modal.btncancel")}
          </Button>
          <Button
            variant="outline"
            onClick={() => sendValue(value)}
            mx={"xl"}
            disabled={isButtonDisabled}
          >
            {t("addentry.modal.btnsave")}
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

import { gql } from "@apollo/client";

export const MODIFY_JEflwBy = gql`
  mutation modMJefollowBY(
    $itemid: ID!
    $journalid: ID!
    $content: String!
    $createdBy: String!
    $followupAt: DateTime!
    $followupBy: String!
  ) {
    modifyJournalEntry(
      entry: {
        id: $itemid
        journal: $journalid
        createdBy: { name: $createdBy }
        content: $content
        followupAt: $followupAt
        followupBy: { name: $followupBy }
      }
    ) {
      id
      journal {
        id
      }
      createdAt
      content
      createdBy {
        name
      }
      followup
      followupAt
      followupBy {
        name
      }
      lastUpdate
      tags
    }
  }
`;
export default MODIFY_JEflwBy;

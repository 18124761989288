import * as React from 'react';
import GetJournals from "../components/journal/GetJournals"
import { useLoaderData } from "react-router-dom";

export function loader({params}){if("id" in params)return params.id;return null;}

export default function JournalPage() {
    const id = useLoaderData();
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <GetJournals id={id}/>
        </React.Suspense>
    );
}
import { gql } from "@apollo/client";


export const CREATE_ENTRY = gql`
mutation createConsequenceEntry($operationid: ID!,$content: String!, $createdBy: String!, $source: String, $hrl: Boolean, $er: Boolean,  $ek: Boolean, $ef: Boolean, $kr:String){
  addConsequenceEntry(entry:
							{
								operation: $operationid
								content: $content
                source: $source
                hrl: $hrl
                er: $er
                kr: $kr
                ek: $ek
                ef: $ef
                createdBy:
              {
              name: $createdBy
            }
  })
  {
    id
    createdAt
    content
    createdBy{name}
    lastUpdate
    hrl
    er
    kr
    source
  }
}
`; 
  export default CREATE_ENTRY
import * as React from 'react';
import { useQuery } from '@apollo/client';
import { InputLabel, MenuItem, FormControl, Select, ListSubheader } from '@mui/material';
import { GET_ALLJOURNAL } from 'GraphQL/Journals';
import { GET_ALLTASKLIST } from 'GraphQL/Tasklists';
import { useTranslation } from "react-i18next";

export default function ListSelector(props) {
    let filterState = props.filterState || ["CLOSED"];
    let setList = props.onSetList || function (selectedList) { console.log(selectedList) };
    let value = props.value || "";
    let type = props.type;

    const { t } = useTranslation("admin");


    //request 
    let query = GET_ALLJOURNAL;
    switch (type) {
        case "t":
            query = GET_ALLTASKLIST;
            break;
        default:
    }
    const { loading, error, data } = useQuery(query);


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;

    //sort the List by Operation
    let listByOp = new Map();
    data.list.forEach((element) => {
        if (isFiltered(element)) return;
        let opname = "Root";
        if (element.operation !== null) opname = element.operation.name;
        let group = listByOp.get(opname) || [];
        group.push(element);
        listByOp.set(opname, group);
    });


    //add a SubHeader per Operation followed by the corresponding journals
    let menu = [];
    listByOp.forEach((value, key) => {
        menu.push(<ListSubheader key={key}>{key}</ListSubheader>);
        value.forEach((listElem) => menu.push(<MenuItem key={listElem.id} value={listElem.id}>{listElem.name}</MenuItem>));
    });

    //Filter out unwanted (by default CLOSED are not shown)
    function isFiltered(element) {
        //by State
        if (filterState.includes(element.state)) {
            return true;
        }
        return false
    }

    function handleSelect(selection) {
        setList(data.list.find(o => o.id === selection.target.value));
    }

    if (data.list.filter(e => e.id === value).length === 0) value = "";

    let label = t("journal");
    switch(type){
        case "t":
            label = t("tasklist");
            break;
        default:
    }

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="grouped-select">{label}</InputLabel>
            <Select value={value} id="grouped-select" label={label} onChange={handleSelect}>
                {menu}
            </Select>
        </FormControl>
    );
}
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import CREATE_ENTRY from "GraphQL/AddMaxEntryFullVariablesTask";
import { LOAD_ENTRIEST } from "GraphQL/Tasklists";
import { Tooltip } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { IconButton } from "@mui/material";
import {
  Modal,
  Button,
  TextInput,
  Textarea,
  useMantineTheme,
  Group,
  NativeSelect,
} from "@mantine/core";
import TodayIcon from "@mui/icons-material/Today";
import moment from "moment";

import { DateTimePicker } from "@mantine/dates";
import { useDisclosure } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

export default function AddEntry(tasklistid) {
  const [opened, { open, close }] = useDisclosure(false);
  const [name, setName] = useState("");
  const [responsible, setResponsible] = useState("");
  const [recipient, setrecipient] = useState("");
  const [content, setContent] = useState("");
  const [priority, setPrio] = useState("MEDIUM");
  const [status, setStatus] = useState("OPEN");
  const [finishdate, setfinishdate] = useState("");
  const theme = useMantineTheme();
  const { t } = useTranslation("tasklist");
  const isButtonDisabled = name.length < 1 || content.length < 1;

  const sendValue = (e) => {
    if (priority === "") {
      setPrio("MEDIUM");
    }
    let duedate;
    if (finishdate !== "") {
      const originalDate = new Date(finishdate);
      const momentObject = moment(originalDate);
      duedate = momentObject.format("YYYY-MM-DDTHH:mm");
    } else {
      duedate = null;
    }

    // if (duedate === "") console.log("date vide")
    if (duedate === "") {
      addjournalentry({
        variables: {
          name: name,
          content: content,
          tasklistid: window.journalid,
          createdBy: name,
          priority: priority,
          status: status,
          responsible: responsible,
          recipient: recipient,
        },
        refetchQueries: [
          { query: LOAD_ENTRIEST, variables: { tasklist: window.journalid } },
        ],
      });
    } else {
      addjournalentry({
        variables: {
          name: name,
          content: content,
          tasklistid: window.journalid,
          createdBy: name,
          priority: priority,
          status: status,
          responsible: responsible,
          recipient: recipient,
          due: duedate,
        },
        refetchQueries: [
          { query: LOAD_ENTRIEST, variables: { tasklist: window.journalid } },
        ],
      });
    }

    setContent("");
    setName("");
    setPrio("MEDIUM");
    setStatus("OPEN");
    setResponsible("");
    setrecipient("");
    close();
    setfinishdate("");
  };

  const handleCloseModal = () => {
    setContent("");
    setName("");
    setPrio("MEDIUM");
    close();
    setStatus("OPEN");
    setResponsible("");
    setfinishdate("");
  };

  const [addjournalentry, { loading, error }] = useMutation(CREATE_ENTRY);

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  const enabled = window.journalid !== undefined;
  const Priorities = [
    {
      value: "MEDIUM",
      label: t("materialreacttable.MEDIUM"),
    },
    {
      value: "HIGH",
      label: t("materialreacttable.HIGH"),
    },
    {
      value: "LOW",
      label: t("materialreacttable.LOW"),
    },
  ];
  const statuss = [
    {
      value: "OPEN",
      label: t("materialreacttable.open"),
    },
    {
      value: "FINISHED",
      label: t("materialreacttable.finished"),
    },
    {
      value: "ARCHIVED",
      label: t("materialreacttable.archived"),
    },
  ];

  return (
    <div>
      <Tooltip title={t("addentry.btnaddentry")}>
        <span>
          <IconButton
            className="btn"
            onClick={() => open()}
            disabled={!enabled}
            type="button"
          >
            <NoteAddIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Modal
        opened={opened}
        onClose={handleCloseModal}
        title={t("modal.titlenewentry")}
        size="xl"
        overlayProps={{
          color:
            theme.colorScheme === "dark"
              ? theme.colors.dark[9]
              : theme.colors.gray[2],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <TextInput
          margin="10px"
          label={t("modal.lblcreatorname")}
          placeholder={t("modal.lblcreatorname")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          data-autofocus
          withAsterisk
          ta={"start"}
          mx={"xl"}
          m={"xl"}
        />
        <Textarea
          type="text"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          id="outlined-multiline-static"
          margin="5px"
          label={t("modal.lblcontent")}
          withAsterisk
          minRows={4}
          maxRows={4}
          radius="md"
          mx={"xl"}
        />
        <TextInput
          label={t("modal.lblresponsible")}
          type="responsible"
          value={responsible}
          onChange={(e) => setResponsible(e.target.value)}
          radius="md"
          mx={"xl"}
        />
        <TextInput
          label={t("modal.lblrecipient")}
          type="recipient"
          value={recipient}
          onChange={(e) => setrecipient(e.target.value)}
          radius="md"
          mx={"xl"}
        />
        <NativeSelect
          label={t("modal.status")}
          data={statuss}
          onChange={(e) => setStatus(e.target.value)}
          //    getcreatelabel={(query) => `+ Create ${query}`}
          /*      onCreate={(query) => {
        const item = { value: query, label: query };
        setStatus((current) => [...current, item]);
        return item;
      }} */
          radius="md"
          mx={"xl"}
        />
        <NativeSelect
          label={t("modal.priority")}
          data={Priorities}
          onChange={(event) => setPrio(event.currentTarget.value)}
          placeholder={t("modal.priority")}
          // getcreatelabel={(query) => `+ Create ${query}`}
          /*      onCreate={(query) => {
        const item = { value: query, label: query };
        setStatus((current) => [...current, item]);
        return item;
      }} */
          radius="md"
          mx={"xl"}
        />
        <DateTimePicker
          icon={<TodayIcon size="1.1rem" stroke={1.5} />}
          label={t("modal.date")}
          placeholder={t("modal.date")}
          //value={finishdate}
          onChange={setfinishdate}
          radius="md"
          mx={"xl"}
        />

        <Group mt="xl">
          <Button
            variant="outline"
            onClick={(e) => {
              handleCloseModal(e);
            }}
            size="sm"
            mx={"xl"}
          >
            {t("modal.cancel")}
          </Button>
          <Button
            variant="outline"
            onClick={sendValue}
            mx={"xl"}
            disabled={isButtonDisabled}
          >
            {t("modal.save")}
          </Button>
        </Group>
      </Modal>
    </div>
  );
}

import * as React from 'react';
import GetTaskLists from "../components/tasklist/GetAllTaskLists"
import { useLoaderData } from "react-router-dom";

export function loader({params}){if("id" in params)return params.id;return null;}

export default function TasklistIdPage() {
    const id = useLoaderData();
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <GetTaskLists id={id}/>
        </React.Suspense>
    );
}
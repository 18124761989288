import { gql } from "@apollo/client";

export const LOAD_TASKLISTS = gql`
query GetallTasklists
{
  allTasklist
  {
    id
    name
    state
    operation {
      id
      name
    }
  }
}
`;

export default LOAD_TASKLISTS;
import { gql } from "@apollo/client";

export const GET_PROJECT = gql`
query GetProjects {
  allProjectEntry {
    description
    projectNo
    id
    a {
      state
      txt
    }
    f {
      state
      txt
    }
    hrl
    id
    l {
      state
      txt
    }
    lastUpdate
    name
    open
    p {
      state
      txt
    }
    path
    presentation
    projectLead
    state
    affected
    cell
    due
    due
    hrl2ic
    projectParticipants
    supervisor
    lastComment
  }
}
`;



export const modPE = gql`
mutation modPE(
  $id: ID!,
  $projectNo: String,
  $description: String,
  $personels: PALFState,
  $personelc: String,
  $trainings: PALFState,
  $trainingc: String,
  $logisticc: String,
  $logistics: PALFState,
  $managementc: String,
  $managements: PALFState,
  $hrl: String,
  $name: String!,
  $open: String,
  $path: String,
  $projectLead: String,
  $state: ProjectEntryState,
  $presentation: String,
  $affected: String,
  $cell: String,
  $hrl2ic: String,
  $projectParticipants: String,
  $supervisor: String,
  $statusfor: DateTime,
  $lastComment: String
) {
  modifyProjectEntry(
    projectEntry: {
      id: $id,
      name: $name,
      p: {state: $personels, txt: $personelc}
      a: {state: $trainings, txt: $trainingc}
      l: {state: $logistics, txt: $logisticc}
      f: {state: $managements, txt: $managementc}
      description: $description,
      hrl: $hrl,
      due : $statusfor
      open: $open,
      path: $path,
      projectNo : $projectNo,
      projectLead: $projectLead,
      state: $state,
      presentation: $presentation,
      affected: $affected,
      cell: $cell,
      hrl2ic: $hrl2ic,
      projectParticipants: $projectParticipants,
      supervisor: $supervisor
      lastComment : $lastComment
    }
  ) {
    p {
      state
      txt
    }
    a {
      state
      txt
    }
    l {
      state
      txt
    }
    f {
      state
      txt
    }
    hrl
    due
    description
    id
    lastUpdate
    projectNo
    name
    open
    due
    path
    presentation
    projectLead
    state
  }
}
`;

export const ADDPE = gql`
mutation addPE(
  $statusfor: DateTime,
  $description: String,
  $personels: PALFState,
  $projectNo: String,
  $personelc: String,
  $trainings: PALFState,
  $trainingc: String,
  $logisticc: String,
  $logistics: PALFState,
  $managementc: String,
  $managements: PALFState,
  $hrl: String,
  $name: String!,
  $open: String,
  $path: String,
  $projectLead: String,
  $state: ProjectEntryState,
  $presentation: String,
  $affected: String,
  $cell: String,
  $hrl2ic: String,
  $projectParticipants: String,
  $supervisor: String
  $lastComment: String
) {
  addProjectEntry(
    projectEntry: {
      name: $name,
      p: {state: $personels, txt: $personelc}
      a: {state: $trainings, txt: $trainingc}
      l: {state: $logistics, txt: $logisticc}
      f: {state: $managements, txt: $managementc}
      description: $description,
      hrl: $hrl,
      due : $statusfor
      open: $open,
      path: $path,
      projectNo : $projectNo,
      projectLead: $projectLead,
      state: $state,
      presentation: $presentation,
      affected: $affected,
      cell: $cell,
      hrl2ic: $hrl2ic,
      projectParticipants: $projectParticipants,
      supervisor: $supervisor
      lastComment : $lastComment
    }
  ) {
    p {state, txt}
    a {state, txt}
    l {state, txt}
    f {state, txt}
    description
    hrl
    id
    lastUpdate
    name
    due
    open
    path
    presentation
    projectLead
    state
    hrl2ic
  }
}
`;






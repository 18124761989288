import JournalImg from "pictures/GefechtsJournalround.png";
import PendingImg from "pictures/pendenzenListe.png";
import ConsequenzImg from "pictures/consequnezenlisteround.png";


export default function LandingPage() {
  return (
    <div className="App" >
      <header className="App-header">
         <a href="/journal">
          <img alt="Qries" src={JournalImg} style={{ margin: '60px'  }}></img>
        </a>
        <a href="/taskList">
          <img alt="Qries" src={PendingImg} style={{ margin: '60px' }}></img>
        </a>
        <a href="/consequences">
          <img alt="Qries" src={ConsequenzImg} style={{ margin: '60px' }}></img>
        </a>
      </header>
    </div>
  );
}

import { gql } from "@apollo/client";

export const GET_ALLOPERATION = gql`

query AllOp{
    operations : allOperation
    {
        id
        name
        state
    }

}
`;

export const ADD_OPERATION = gql`

  mutation CreateOp($name: String!) {
      newOperation : addOperation(operation:
      {
          name: $name
      }
    )
    {
      id
      name
      state
    }
  }

`;

export const MOD_OPERATION = gql`

mutation ModOp($op: OperationInputMod!) {
  modifyOperation(operation:$op){
    id
    name
    state
  }
}

`;


import * as React from 'react';
import Getconsequences from "../components/GsVb/GsVb"

import { useLoaderData } from "react-router-dom";

export function loader({params}){if("id" in params)return params.id;return null;}

export default function ConsequencesIdPage() {
    const id = useLoaderData();
    return (
        <React.Suspense fallback={<div>loading...</div>}>
            <Getconsequences id={id}/>
        </React.Suspense>
    );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import { List, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info'
import DirectionsIcon from '@mui/icons-material/Directions';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



export default function MenuDrawer(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    function handleChangelanguage(event) {
        i18n.changeLanguage(event);
        window.location.reload(false);
    };

    function navigateTo(target) {
        navigate(target);
        props.drawer_close();
    }

    return (
      <Box sx={{ width: 250 }} role="presentation">
        <List>
          <ListItem
            key="journal"
            disablePadding
            onClick={() => navigateTo("journal")}
          >
            <ListItemButton>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t("journal")} />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            key="tasklist"
            disablePadding
            onClick={() => navigateTo("tasklist")}
          >
            <ListItemButton>
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText primary={t("tasklist")} />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            key="consequences"
            disablePadding
            onClick={() => navigateTo("consequences")}
          >
            <ListItemButton>
              <ListItemIcon>
                <DirectionsIcon />
              </ListItemIcon>
              <ListItemText primary={t("consequences")} />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            key="process"
            disablePadding
            onClick={() => navigateTo("process")}
          >
            <ListItemButton>
              <ListItemIcon>
                <DoubleArrowIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <span>
                    {t("process")}
                    <Box
                      component="span"
                      sx={{
                        border: '1px solid grey',
                        borderRadius: '4px',
                        padding: '2px 4px',
                        marginLeft: '8px',
                        fontSize: '0.75rem',
                        color: 'white',
                        backgroundColor: 'grey',
                        position: 'relative',
                        top: '-8px' // Adjusted value to move the frame further up
                      }}
                    >
                      beta
                    </Box>
                  </span>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            key="pm"
            disablePadding
            onClick={() => navigateTo("Promanager")}
          >
            <ListItemButton>
              <ListItemIcon>
                <EqualizerIcon />
              </ListItemIcon>
              <ListItemText
        primary={
          <span>
            {t("Project manager")}
            <Box
              component="span"
              sx={{
                border: '1px solid grey',
                borderRadius: '4px',
                padding: '2px 4px',
                marginLeft: '8px',
                fontSize: '0.75rem',
                color: 'white',
                backgroundColor: 'grey',
                position: 'relative',
                top: '-8px' 
              }}
            >
              beta
            </Box>
          </span>
        }
      />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem
            key="admin"
            disablePadding
            onClick={() => navigateTo("admin")}
          >
            <ListItemButton>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={t("admin")} />
            </ListItemButton>
          </ListItem>
        </List>
        <List>
          <ListItem
            key="about"
            disablePadding
            onClick={() => navigateTo("about")}
          >
            <ListItemButton>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={t("about")} />
            </ListItemButton>
          </ListItem>
        </List>
        <Button
          onClick={(e) => {
            handleChangelanguage("de");
          }}
        >
          DE
        </Button>
        <Button
          onClick={(e) => {
            handleChangelanguage("fr");
          }}
        >
          FR
        </Button>
        <Button
          onClick={(e) => {
            handleChangelanguage("eng");
          }}
        >
          EN
        </Button>
      </Box>
    );
}
